import Vue from 'vue'
import Router from 'vue-router'

//import CadastroInternauta from '@/views/formcadastrointernauta'
import Home from '@/views/Home.vue'
import PainelContato from '@/views/PainelContatos.vue'
import Login from '@/views/Login.vue'
import CadastroAvaliacao from '@/views/CadastroAvaliacao'
import OcupaAnalitico from '@/views/OcupacaoAnalitico'
import Configuracoes from '@/views/Configuracoes'
import PortalFaleConosco from '@/views/PortalFaleConosco'
import MensagensFaleConosco from '@/views/MensagensFaleConosco'
import ListaHospedes from '@/views/Hospedes'
import Usuarios from '@/views/Usuarios.vue'
import FormEmail from '@/views/FormEmail'
import RecuperacaoSenha from '@/views/RecuperacaoSenha.vue'
import MudarSenha from '@/views/MudarSenha.vue'
import FormWhatsapp from '@/views/FormWhatsappMessage'
import FormAdmCadastroInternauta from '@/views/FormAdmCadastroInternauta'
import FormAutoCadastroInternauta from '@/views/FormAutoCadastroInternauta'
import ObjArrayToExcelTester from '@/components/ObjArrayToExcelTester'
import Relatorios from '@/views/Relatorios'
import DashboardAnalitico from '@/views/DashboardAnalitico.vue'

/* import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination); */


Vue.use(Router)

const routes = [
    /*     {
            name : 'Cadastrointernauta',
            path : '/cadastrointernauta',
            component : CadastroInternauta
        }, */
    {
        name: 'Cadastro Internauta',
        path: '/cadastrointernauta',
        component: FormAdmCadastroInternauta
    },
    {
        name: 'Relatorios',
        path: '/relatorios',
        component: Relatorios
    },
    {
        name: 'Dashboard Analitico',
        path: '/analitico',
        component: DashboardAnalitico
    },
    {
        name: 'Internauta - Cadastre-se',
        path: '/autocadastrointernauta',
        component: FormAutoCadastroInternauta
    },
    {
        name: 'ObjArrayToExcelTester',
        path: '/teste/objarraytoexceltester',
        component: ObjArrayToExcelTester
    },
    {
        name: 'Home',
        path: '/home',
        component: Home
    },
    {
        name: 'Painel',
        path: '/painelcontato',
        component: PainelContato
    },
    {
        name: 'Login',
        path: '/',
        component: Login
    },
    {
        name: 'Cadastro de Avaliação',
        path: '/cadastroavaliacao',
        component: CadastroAvaliacao
    },
    {
        name: 'Analítico de Ocupação',
        path: '/ocupacaoanalitico',
        component: OcupaAnalitico
    },
    {
        name: 'Configurações',
        path: '/config',
        component: Configuracoes
    },
    {
        name: 'Portal Fale Conosco',
        path: '/faleconosco',
        component: PortalFaleConosco
    },
    {
        name: 'Mensagens do Portal Fale Conosco',
        path: '/faleconosco/mensagens',
        component: MensagensFaleConosco
    },
    {
        name: 'Hóspedes na Casa',
        path: '/hospedes',
        component: ListaHospedes
    },
    {
        name: 'Usuários',
        path: '/usuarios',
        component: Usuarios
    },
    {
        name: 'Email',
        path: '/formemail',
        component: FormEmail
    },
    {
        name: 'Restauração de Senha',
        path: '/forgot',
        component: RecuperacaoSenha
    },
    {
        name: 'Mensagem WhatsApp',
        path: '/mensagemwhatsapp',
        component: FormWhatsapp
    },
    {
        name: 'Altera de Senha',
        path: '/mudarsenha',
        component: MudarSenha
    }

]

const router = new Router({
    routes,
    mode: 'history'
})

export default router