<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-container class="pa-4">
        <v-row>
          <v-card width="400" class="ma-2">
            <v-toolbar color="blue" dark
              ><v-toolbar-title style="color: fff"
                >Logins Internautas</v-toolbar-title
              ></v-toolbar
            >
            <v-card-text>
              <div class="pa-2" style="heigth: 100%">
                <p>
                  <span> Logins Aceitos: {{ logins.accept.count }} </span>
                </p>
                <p>
                  <span> Logins Rejeitados: {{ logins.reject.count }} </span>
                </p>
                <p>
                  <span> Logins Total: {{ logins.total }} </span>
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn x-small>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card width="400" class="ma-2">
            <v-toolbar color="blue" dark
              ><v-toolbar-title style="color: fff"
                >Cadastro de Internautas</v-toolbar-title
              ></v-toolbar
            >
            <v-card-text style="heigth: 100% !important">
              <div class="pa-2" style="heigth: 100%">
                <p>
                  <span> Cadastrados hoje: {{ cadastros.length }} </span>
                </p>
                <p><span>T </span></p>
                <p><span>T </span></p>
              </div>
              <v-card-actions>
                <v-btn x-small>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
          <v-card width="400" class="ma-2">
            <v-toolbar color="blue" dark
              ><v-toolbar-title style="color: fff"
                >Sites</v-toolbar-title
              ></v-toolbar
            >
            <v-card-text style="heigth: 100% !important">
              <div class="pa-2" style="heigth: 100%">
                <p>
                  <span>Mais acessado hoje: instagram.com.br</span>
                </p>
                <p><span>Total de hits hoje: 50987 </span></p>
                <p><span>T </span></p>
              </div>
              <v-card-actions>
                <v-btn x-small>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
      logins: {},
      cadastros: [],
    };
  },
  components: {
    NavBar,
    Footer,
  },
  methods: {
    async buscaLogin() {
      await this.$http("/dashboard/consultaloginsinternautas")
        .then((resp) => {
          this.logins = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async buscaTotalCadastros() {
      await this.$http("/dashboard/consultacadastrosinternautas")
        .then((resp) => {
          this.cadastros = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.buscaLogin();
    this.buscaTotalCadastros();
  },
};
</script>