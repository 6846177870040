<template>
  <v-app>
    <v-container>
      <Navbar :drawer="false" />
      <v-main> 
          <ListaHospedes />
      </v-main>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import ListaHospedes from '@/components/ListaHospedes.vue'
export default {
    data(){
        return{

        }
    },
      components: {
    Footer,
    Navbar,
    ListaHospedes
    
  },
  created(){


      
  }
};
</script>