<template>
  <v-app>
    <div id="app">
        <router-view></router-view>
    </div>
  </v-app>
</template>

<script>


export default {
  name: 'App',
  components: {

  },

  data: () => ({
    isAdmin : false,
    token : localStorage.getItem('espottoken'),
    noLoged : localStorage.getItem('nolog') || false
  }),
  mounted(){
/*     if((!this.token && !this.noLoged) || !this.token){
      window.location.href = "/";
      localStorage.setItem("nolog", true);
    } */


  }
};
</script>
