<template>
  <div>
    <v-card class="p-2">

      <v-row class="m-2">
        <v-spacer></v-spacer>
        <export-excel :data="msgs" :fields="dataFileds" name="faleconosco.xls">
          <v-btn class="mx-2 primary" dark small><v-icon small>mdi-cloud-download</v-icon>&nbsp;&nbsp; Excel</v-btn>
        </export-excel>
      </v-row>

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="msgs"
        :search="search"
      ></v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    dataini: String,
    datafim: String,
  },
  data() {
    return {
      dataFileds: {
        Apartamento: "coduh",
        "Nº Reserva": "numreserva",
        Mensagem: "mensagem",
        Nota: "notamensagem",
        "Data Postagem": "datahoracriacao",
      },
      search: "",
      headers: [
        {
          text: "Apartamento",
          align: "start",
          filterable: true,
          width: "10px",
          value: "coduh",
        },
        { text: "Nº Reserva", value: "numreserva", width: "110px" },
        { text: "Mensagem", value: "mensagem", "max-width": "200px" },
        { text: "Nota", value: "notamensagem", width: "10px" },
        { text: "Data Postagem", value: "datahoracriacao", width: "150px" },
      ],
      msgs: [],
    };
  },
  created() {
    this.$http("/portal/buscamensagens", {
      params: { dataini: this.dataini, datafim: this.datafim },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("espottoken"),
      },
    })
      .then((response) => {
        if (response.status == 200) {
          this.msgs = response.data.mensagens;
        }
      })
      .catch((error) => {
        window.location.href = "/";
        console.log(error);
      });
  },
  methods: {},
};
</script>