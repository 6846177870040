<template>
  <v-card class="p-2">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="hospedes"
      :search="search"
      locale="pt-BR"
      mobile-breakpoint="350"
      loading="20"
      loading-text="Carregando.."
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "UH",
          align: "start",
          filterable: true,
          width: "10px",
          value: "coduh",
        },
        { text: "Nº Reserva", value: "numreserva", width: "100px" },
        { text: "Nome", value: "nome", width: "80px" },
        { text: "Sobrenome", value: "sobrenome", width: "80px" },
        { text: "Principal", value: "hospedeprincipaltxt", width: "80px" },
        { text: "Cliente", value: "empresareservante", "width": "200px" },
        { text: "Tipo de Hóspede", value: "tipohospede", width: "150px" },
        { text: "Pensão", value: "pensao", width: "100px" },
        { text: "Check-IN", value: "datacheckin", width: "150px" },
        { text: "Check-OUT", value: "datacheckout", width: "150px" },
      ],
      hospedes: [],
    };
  },
  created(){
      this.$http("/ocupacao/hospedesnacasa", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.hospedes = response.data;
          //console.log(this.desserts)
        }
      }).catch(error =>{
          window.location.href = "/";
          console.log(error)
      })
  }
}



</script>