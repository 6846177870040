<template>
  <div>
    <v-app-bar app color="rgb(29, 69, 150)" flat>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        color="white"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <a href="/home"
          ><v-img
            :src="'data:image.png;base64,' + logoString"
            max-width="130"
            max-height="65"
          ></v-img>
          <!-- <v-icon x-large style="color: white;">mdi-crown</v-icon> -->
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <span style="color: #fff">Sair</span> -->
      <v-btn icon @click="logOut">
        <v-icon color="white">mdi-power</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-system-bar height="30" color="rgb(29, 69, 150)">
        <span style="color: #f6f6f6">Economy Software</span
        ><v-spacer></v-spacer>

        <v-icon color="white" @click="drawer = !drawer"
          >mdi-arrow-left-circle-outline</v-icon
        >
      </v-system-bar>

      <v-card height="100" width="256">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title"> </v-list-item-title>
            <v-list-item-subtitle>
              {{ nomeCliente }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item link href="/analitico">
            <v-list-item-icon>
              <v-icon color="teal purple">mdi-desktop-mac-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="/formemail">
            <v-list-item-icon>
              <v-icon color="teal darken-2"> mdi-email </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> E-mail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="/mensagemwhatsapp">
            <v-list-item-icon>
              <v-icon color="teal darken-2"> mdi-whatsapp</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>WhatsApp</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--           <v-list-item link>
            <v-list-item-icon>
              <v-icon color="blue-grey darken-2">mdi-access-point</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title @click="drawer = !drawer"
                >Internet/Wi-Fi</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->

          <!--           <v-list-item link>
            <v-list-item-icon>
              <v-icon color="blue darken-2"> mdi-message-text </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title @click="drawer = !drawer"
                >SMS/Whats</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link href="/relatorios">
            <v-list-item-icon>
              <v-icon color="blue darken-2">mdi-database</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title @click="drawer = !drawer"
                >Relatórios</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="/usuarios" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon color="orange darken-2">mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Usuários E-Spot</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="/cadastrointernauta">
            <v-list-item-icon>
              <v-icon color="red darken-2">mdi-account-group-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Internautas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="/config" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon color="purple darken-2"> mdi-wrench </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title @click="drawer = !drawer"
                >Configurações</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="#">
            <v-list-item-icon>
              <v-icon color="green darken-2"> mdi-help </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title @click="drawer = !drawer"
                >Ajuda</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>
<script>
import CONF from "@/config";
export default {
  name: "Navbar",
  drawer: false,
  data() {
    return {
      isAdmin: localStorage.getItem("isEspotAdmin") || false,
      nomeCliente: CONF.nomeCliente,
      toggleDisable: true,
      drawer: false,
      logoString: localStorage.getItem("logoEspotEmpresaprop"),
    };
  },
  methods: {
    logOut() {
      localStorage.clear();
      localStorage.setItem("nolog", false);
      window.location.href = "/";
    },
  },
  mounted() {},
  created() {},
};
</script> 