<template>
  <v-card max-width="600px" class="pa-2 mb-6">
    <v-card-title>
      <v-icon color="#008000">mdi-whatsapp</v-icon>
      <span style="color: #008000">Notificações Programadas</span>
    </v-card-title>
    <v-card-subtitle>Agendamento de Mensagens WhatsApp</v-card-subtitle>
    <div v-if="chatapi.ativo">
      <v-textarea
        label="Mensagem Agendada..."
        class="pa-2"
        v-if="chatapi.notificacaodiaria"
        v-model="agendaWhatsApp.mensagem"
        rows="4"
        no-resize
        style="background-color: #dcdcdc; border-radius: 5px"
      >
      </v-textarea>
      <div v-if="chatapi.notificacaodiaria">
        <v-text-field
          v-model="agendaWhatsApp.horarioenvio"
          type="time"
          style="width: 100px"
          label="Horário de envio"
        >
        </v-text-field>
        <v-switch v-model="agendaWhatsApp.ativo" label="Mensagem Ativa"></v-switch>

        <v-btn class="m-2 primary" small v-bind:disabled="isUpdate" @click="salvaAgendaWhats"> <v-icon small>mdi-plus</v-icon> Inserir</v-btn>

        <v-btn v-bind:disabled="!isUpdate" @click="salvaAgendaWhats" class="m-2" color='green' small><v-icon small>mdi-content-save</v-icon>Salvar</v-btn>
        <v-btn @click="deletaAgendaWhats" class="m-2 error" small><v-icon small>mdi-delete</v-icon>Excluir</v-btn>
        <v-btn
          class="m-2"
          color='orange'
          small
          @click="
            (agendaWhatsApp.mensagem = ''),
            (agendaWhatsApp.horarioenvio = ''),
            (agendaWhatsApp.idcrontabwhatsapp = 0),
            (isUpdate = false)
          "
          ><v-icon small>mdi-close</v-icon>Limpar</v-btn
        >
      </div>
      <v-data-table
        v-if="chatapi.notificacaodiaria"
        v-model="selectedRow"
        :headers="headers"
        :items="mensagensAgendadas"
        :items-per-page="5"
        :single-select="true"
        show-select
        item-key="idcrontabwhatsapp"
        locale="pt-BR"
        mobile-breakpoint="350"
        loading="20"
        loading-text="Carregando.."
      >
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    chatapi: {},
  },
  data() {
    return {
      isUpdate : false,
      agendaObj :{},
      agendaWhatsApp: {
        mensagem: "",
        horarioenvio: "",
        ativo: true,
      },
      idcrontabwhatsapp: 0,
      selectedRow: [],
      mensagensAgendadas: [],
      headers: [
        {
          text: "Mensagem",
          align: "start",
          filterable: true,
          width: "250px",
          value: "mensagem",
        },
        { text: "Horário", value: "horarioenvio", width: "100px" },
        { text: "Ativo", value: "ativotxt", width: "50px" },
      ],
    };
  },
  methods: {
    buscaChatApi() {
      this.$http("/whatsapp/buscachatapi", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.apiurl) {
              this.chatapi.ativo = response.data.ativo;
              this.chatapi.apiurl = response.data.apiurl;
              this.chatapi.token = response.data.token;
              this.chatapi.permitenotificacaoestadia =
                response.data.permitenotificacaoestadia;
              this.chatapi.notificacaodiaria = response.data.notificacaodiaria;
              this.chatapi.mensagemdiariapadrao =
                response.data.mensagemdiariapadrao;
              this.chatapi.horaenviomensagemdiario =
                response.data.horaenviomensagemdiario;
              this.chatapi.idwhatsappchatapi = response.data.idwhatsappchatapi;
              
            }
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    buscaAgendaWhatsApp() {
      this.$http("/whatsapp/buscaagendaenviowhats", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.mensagensAgendadas = response.data;
          }

          //console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async salvaAgendaWhats() {
      if (
        this.agendaWhatsApp.mensagem.length < 1 ||
        this.agendaWhatsApp.horarioenvio.length < 1
      ) {
        return;
      }
      if(this.isUpdate){
          this.agendaObj = {
                      mensagem : this.agendaWhatsApp.mensagem,
                      horarioenvio : this.agendaWhatsApp.horarioenvio,
                      ativo : this.agendaWhatsApp.ativo,
                      idcrontabwhatsapp : this.agendaWhatsApp.idcrontabwhatsapp
                      }
      }else{
          this.agendaObj = {
              mensagem : this.agendaWhatsApp.mensagem,
              horarioenvio : this.agendaWhatsApp.horarioenvio,
              ativo : this.agendaWhatsApp.ativo
              }
      }


      await this.$http
        .post("/whatsapp/insereagendaenviowhats", this.agendaObj, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.agendaWhatsApp.mensagem = "";
            this.agendaWhatsApp.horarioenvio = "";
            this.agendaWhatsApp.ativo = true
            this.isUpdate = false;
            setTimeout(() => {
              this.buscaAgendaWhatsApp();
            }, 1000);
          } else {
            //alert("Error");
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async deletaAgendaWhats() {
      if (this.selectedRow[0].idcrontabwhatsapp < 1) {
        return;
      }
      await this.$http
        .post(
          "/whatsapp/deletaagendaenviowhats",
          { idcrontabwhatsapp: this.selectedRow[0].idcrontabwhatsapp },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("espottoken"),
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            setTimeout(() => {
              this.isUpdate = false
              this.agendaWhatsApp.mensagem = "";
              this.agendaWhatsApp.horarioenvio = "";
              this.agendaWhatsApp.ativo = true
              this.agendaWhatsApp.idcrontabwhatsapp = 0;
              this.buscaAgendaWhatsApp();
              
            }, 1000);
          } else {
            //alert("Error");
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    selectedRow: function () {
      if (this.selectedRow.length > 0) {
        //this.idcrontabwhatsapp = this.selectedRow[0].idcrontabwhatsapp;
        this.agendaWhatsApp = this.selectedRow[0]
        this.isUpdate = true

      } else {
        this.agendaWhatsApp.idcrontabwhatsapp = 0;
        this.agendaWhatsApp.mensagem = '';
        this.agendaWhatsApp.horarioenvio = '';
        this.agendaWhatsApp.ativo = true ;
        this.isUpdate = false
        this.buscaAgendaWhatsApp()
      }
    },

    chatapi: function () {
      if (this.chatapi.ativo == false) {
        this.chatapi.permitenotificacaoestadia = false;
        this.chatapi.notificacaodiaria = false;
      }
    },
  },
  mounted() {
    this.buscaChatApi();

    this.buscaAgendaWhatsApp();
  },
};
</script>