<template>
  <v-app>
    <NavBar />
    <v-main class="pb-12">
      <v-row class="m-2 justify-center">
        <v-col cols="12" sm="4" class="ma-2">
          <v-card width="400px" class="pa-2 pb-4">
            <v-card-title>
              <v-icon color="rgb(29, 69, 150)">mdi-email</v-icon>
              <span style="color: rgb(29, 69, 150)">Email</span>
            </v-card-title>
            <v-text-field
              append-icon="mdi-email"
              type="e-mail"
              label="Endereço/Usuário"
              v-model="contaEmail.user"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-key-variant"
              type="password"
              label="Senha"
              v-model="contaEmail.pass"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-server"
              type="text"
              label="Host/Servidor"
              v-model="contaEmail.host"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-launch"
              type="number"
              label="Porta"
              v-model="contaEmail.port"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-backburger"
              type="text"
              label="Responder para"
              v-model="contaEmail.replyto"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-email-open"
              type="text"
              label="Apresentação"
              v-model="contaEmail.nametoexibition"
            >
            </v-text-field>
            <v-text-field
              append-icon="mdi-comment-alert"
              type="text"
              label="Email para notificações"
              v-model="contaEmail.emailnotify"
            >
            </v-text-field>
            <v-row>
              <v-spacer></v-spacer>
              <div style="height: 50px">
                <v-col
                  ><span v-if="emailOK">{{ responseEmail }}</span></v-col
                >
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="m-2 pa-2">
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="success"
                @click="cadastraContaEmail()"
                ><v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <!--               <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                @mouseover="`Editar`"
                ><v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <!-- <v-spacer></v-spacer> -->
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="orange"
                @click="testaEmail()"
                ><v-icon>mdi-play-circle-outline</v-icon>
              </v-btn>
              <!-- <v-spacer></v-spacer> -->
              <!--               <v-btn class="mx-2" fab dark small color="error"
                ><v-icon>mdi-exit-to-app</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="ma-2">
          <v-card width="600px" class="pa-2 pb-4">
            <v-card-title>
              <v-icon color="#008000">mdi-whatsapp</v-icon>
              <span style="color: #008000">Notificações WhatsApp</span>
            </v-card-title>
            <v-switch
              label="Utiliza API Whatsapp *"
              v-model="chatapi.ativo"
            ></v-switch
            ><span
              >Provido por
              <a target="_blank" href="https://app.chat-api.com">Chat-API</a>
            </span>
            <div v-if="chatapi.ativo">
              <v-text-field label="URL" v-model="chatapi.apiurl" required>
              </v-text-field>
              <v-text-field label="TOKEN" v-model="chatapi.token" required>
              </v-text-field>

              <v-switch
                v-model="chatapi.permitenotificacaoestadia"
                label="Permitir notificações durante a estadia."
              ></v-switch>
              <v-switch
                label="Notificações Programadas"
                v-model="chatapi.notificacaodiaria"
              ></v-switch>

              <v-row class="m-2 pa-2">
                <span v-if="chatapi.ativo"
                  >*Tarifas de serviço podem ser cobradas pela Chat-API,
                  provedor que presta o serviço. A Economy Software não intervem
                  de forma alguma nas tarifas, negociações ou uso da ferramenta
                  e está isenta de quaisquer tratativa a respeito.</span
                >
              </v-row>
            </div>
            <v-row>
              <v-spacer></v-spacer>
              <div style="height: 50px">
                <v-col
                  ><span v-if="chatapiOpOK">{{ responseChatAPI }}</span></v-col
                >
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="m-2 pa-2">
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="success"
                @click="gravaChatAPI"
                ><v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="orange"
                @mouseover="`Editar`"
                @click="chatapi.mensagemdiariapadrao = ''"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="mx-2" fab dark small color="error"
                ><v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="ma-2">
          <v-card width="400px" class="pa-2 pb-4">
            <v-card-title>
              <v-icon color="rgb(29, 69, 150)">mdi-image</v-icon>
              <span style="color: rgb(29, 69, 150)">Sua Logo</span>
            </v-card-title>
            <div class="pa-2 m-2">
              <p>
                <input
                  @change="setImage"
                  type="file"
                  name="photo"
                  accept="image/*"
                  id="logo-h"
                />
              </p>
              <div v-if="isImg">
                <img
                  :src="'data:image.png;base64,' + imgSrc"
                  style="
                    height: 65px;
                    width: 130px;
                    border-radius: 5px;
                    background-color: grey;
                  "
                />
                <br />
                <v-row>
                  <v-spacer></v-spacer>
                  <div style="height: 40px">
                    <v-col
                      ><span v-if="uploadImgOK">{{
                        responseUploadImg
                      }}</span></v-col
                    >
                  </div>
                  <v-spacer></v-spacer>
                </v-row>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="success"
                  @click="salvaLogo"
                  ><v-icon>mdi-content-save</v-icon>
                </v-btn>
              </div>
            </div>
            <hr />
            <v-card-title>
              <v-icon color="rgb(29, 69, 150)">mdi-comment-plus-outline</v-icon>
              <span style="color: rgb(29, 69, 150)">Portal Fale Conosco</span>
            </v-card-title>
            <v-switch
              label="Habilita portal fale conosco."
              v-model="portalativo"
            ></v-switch>
            <v-text-field
              v-show="portalativo"
              append-icon="mdi-comment"
              type="text"
              label="Texto padrão portal"
              id="textoportal"
              v-model="textopadrao"
            >
            </v-text-field>
            <v-row>
              <v-spacer></v-spacer>
              <div style="height: 50px">
                <v-col
                  ><span v-if="portalOK">{{ responsePortal }}</span></v-col
                >
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="m-2 pa-2">
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="success"
                @click="gravaInfoPortalFaleConosco()"
                ><v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                @click="textopadrao = ''"
                fab
                dark
                small
                color="orange"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="ma-2">
          <v-card width="400px" class="pa-2 pb-4">
            <v-card-title>
              <v-icon style="color: rgb(29, 69, 150)">
                mdi-format-align-left
              </v-icon>
              <span style="color: rgb(29, 69, 150)">Termo LGPD</span>
            </v-card-title>
            <v-card-text>
              <span>Termo de acordo exibido no cadastro do internauta, relacionado as diretrizes da LGPD.</span>
              <v-file-input 
              accept=".pdf" 
              label="Aquivo termo PDF"
              show-size
              ></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mx-2" fab dark small color="green"
                ><v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
//import AgendaWhatsApp from "@/components/AgendaWhatsApp.vue";
export default {
  data() {
    return {
      uploadImgOK: false,
      responseUploadImg: "",
      imgSrc: "",
      isImg: false,
      contaEmail: {},
      portalativo: false,
      textopadrao: "",
      idportalfaleconosco: 0,
      emailOK: false,
      responseEmail: "",
      responsePortal: "",
      portalOK: false,
      chatapiOpOK: false,
      responseChatAPI: "",
      chatapi: {
        apiurl: "",
        ativo: false /* 
        horaenviomensagemdiario: "00:00:00", */,
        idwhatsappchatapi: 0,
        instanceid: "0" /* 
        mensagemdiariapadrao: "", */,
        notificacaodiaria: false,
        permitenotificacaoestadia: false,
        token: "",
      },
      agendaWhatsApp: {
        mensagem: "",
        horarioenvio: "",
        ativo: true,
      },
      agendamentoAtivo: false,
      idcrontabwhatsapp: 0,
      selectedRow: [],
      mensagensAgendadas: [],
      headers: [
        {
          text: "Mensagem",
          align: "start",
          filterable: true,
          width: "250px",
          value: "mensagem",
        },
        { text: "Horário", value: "horarioenvio", width: "100px" },
        { text: "Ativo", value: "ativo", width: "50px" },
      ],
    };
  },
  components: {
    NavBar,
    Footer /* 
    AgendaWhatsApp, */,
  },
  methods: {
    async testaEmail() {
      let emailTest = {
        destinatario: this.contaEmail.emailnotify,
        assunto: "Email teste.",
        html: "<h3 style='color: rgb(50, 107, 168);'>E-mail de teste do sistema E-Spot Economy Software. </h3><br><h4 style='color: rgb(50, 107, 168);'> Se você recebeu esse email, por favor, salve as configurações na página da web.</h4>",
      };

      await this.$http
        .post("/email/enviaremail", emailTest, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.responseEmail = "OK. Email Enviado";
            this.emailOK = true;
            setTimeout(() => {
              this.emailOK = false;
            }, 3000);
            console.log(response.data.message);
          } else {
            //alert(response.data.error.response);
            //console.log(response.data.error.response)
          }
        })
        .catch((error) => {
          this.responseEmail = error.response.data.error.response;
          this.emailOK = true;
          setTimeout(function () {
            this.emailOK = false;
          }, 3000);
          console.log(error.response);
        });
    },

    async cadastraContaEmail() {
      await this.$http
        .post("/email/cadastracontaemail", this.contaEmail, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.responseEmail =
              "Email cadastrado com sucesso. Por favor, teste.";
            this.emailOK = true;
            setTimeout(() => {
              this.emailOK = false;
            }, 5000);
          } else {
            alert("Error");
            console.log("Error");
          }
        })
        .catch((error) => {
          this.responseEmail = error.response;
          this.emailOK = true;
          setTimeout(() => {
            this.emailOK = false;
          }, 3000);
          console.log(error.response);
        });
    },

    async gravaInfoPortalFaleConosco() {
      let portalObj = {
        ativo: this.portalativo,
        textopadrao: this.textopadrao,
        idportalfaleconosco: this.idportalfaleconosco,
      };

      await this.$http
        .post("/portal/gravainfoportalfaleconosco", portalObj, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.portalativo = response.data.ativo;
            this.textopadrao = response.data.textopadrao;
            this.idportalfaleconosco = response.data.idportalfaleconosco;
            this.portalOK = true;
            this.responsePortal = "Dados do portal gravados com sucesso.";
            setTimeout(() => {
              this.portalOK = false;
            }, 3000);
            console.log(response.data);
          } else {
            alert("Error");
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async gravaChatAPI() {
      await this.$http
        .post("/whatsapp/gravachatapi", this.chatapi, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.chatapiOpOK = true;
            this.responseChatAPI = "Processado com sucesso";
            setTimeout(() => {
              this.chatapiOpOK = false;
            }, 3000);
          } else {
            //alert("Error");
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async setImage(e) {
      const file = e.target.files[0];
      //console.log(file);

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.isImg = true;

          this.imgSrc = event.target.result;
          let index = event.target.result.indexOf(",") + 1;
          //console.log(index);
          //console.log(this.imgSrc);
          this.imgSrc = event.target.result.substring(
            index,
            event.target.result.length
          );
          //console.log(this.imgSrc);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    async salvaLogo() {
      if (!this.imgSrc.length > 0) {
        console.log("invalido");
        return;
      }
      await this.$http
        .post(
          "/logo/salvaLogo",
          { logoempresaprop: this.imgSrc },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "x-access-token": localStorage.getItem("espottoken"),
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.uploadImgOK = true;
            this.responseUploadImg = "Processado com sucesso";
            setTimeout(() => {
              this.uploadImgOK = false;
            }, 3000);
          } else {
            alert("Error");
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async buscaInfoPortal() {
      this.$http("/portal/infoportal")
        .then((response) => {
          this.portalativo = response.data.ativo;
          this.textopadrao = response.data.textopadrao;
          this.idportalfaleconosco = response.data.idportalfaleconosco;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async bucaContaEmail() {
      this.$http("/email/buscacontaemail", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.contaEmail = response.data;
            /*         if(this.contaEmail.idmailer == null){
            console.log("Veio vazio")
        } */
          } else {
            this.contaEmail = {};
          }
        })
        .catch((error) => {
          window.location.href = "/";
          console.log(error);
        });
    },

    async buscaChatAPI() {
      this.$http("/whatsapp/buscachatapi", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.apiurl) {
              this.chatapi.ativo = response.data.ativo;
              this.chatapi.apiurl = response.data.apiurl;
              this.chatapi.token = response.data.token;
              this.chatapi.permitenotificacaoestadia =
                response.data.permitenotificacaoestadia;
              this.chatapi.notificacaodiaria = response.data.notificacaodiaria;
              this.chatapi.mensagemdiariapadrao =
                response.data.mensagemdiariapadrao;
              this.chatapi.horaenviomensagemdiario =
                response.data.horaenviomensagemdiario;
              this.chatapi.idwhatsappchatapi = response.data.idwhatsappchatapi;
            }
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    chatapi: function () {
      if (this.chatapi.ativo == false) {
        this.chatapi.permitenotificacaoestadia = false;
        this.chatapi.notificacaodiaria = false;
      }
    },
  },
  mounted() {
    this.bucaContaEmail();
    this.buscaChatAPI();
    this.buscaInfoPortal();
  },
};
</script>