<template>
  <v-app>
    <Navbar :drawer="false" />
    <v-main style="padding: 10px; margin-bottom: 20px">
      <v-container style="padding-top: 80px">
        <v-form v-model="valid" lazy-validation ref="form">
          <v-row align="center" justify="center">
            <v-card style="width: 1000px">
              <v-toolbar color="blue">
                <v-toolbar-title style="color: white">
                  <v-icon color="white">mdi-account-group-outline</v-icon>
                  Cadastro de Internautas
                  <span style="font-size: 14px !important"
                    >Senha: {{ senhaRadius }}</span
                  >
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-switch v-model="ativo" label="Ativo"></v-switch>
                <v-text-field
                  label="Nome Completo"
                  style="width: 600px"
                  :rules="FullNameRules"
                  v-model="nome"
                >
                </v-text-field>
                <v-text-field
                  label="CPF"
                  style="width: 300px"
                  v-model="cpf"
                  :rules="numerodocumentoRules"
                >
                </v-text-field>
                <v-text-field
                  label="Nascimento"
                  type="text"
                  v-mask="'##/##/####'"
                  placeholder="DD/MM/AAAA"
                  style="width: 300px"
                  v-model="datanascimento"
                  :rules="dateRules"
                >
                </v-text-field>
                <v-text-field
                  label="Celular"
                  style="width: 300px"
                  v-model="telefone"
                  :rules="foneRules"
                  v-mask="'(##)#####-####'"
                  placeholder="(XX)XXXXX-XXXX"
                >
                  <!-- @keyup="clearNonMumeric(telefone, 'telefone')" -->
                </v-text-field>
                <v-text-field
                  label="E-mail"
                  style="width: 600px"
                  v-model="email"
                  :rules="emailRules"
                >
                </v-text-field>

                <!-- <v-text-field label="Senha" style="width: 600px" v-model="senha"> </v-text-field> -->

                <div class="btns-rodape">
                  <v-spacer />
                  <v-btn
                    small
                    color="red"
                    class="btn-rodape"
                    title="Cancelar"
                    :disabled="btnCancelarDisabled"
                    ><v-icon color="white">mdi-trash-can-outline</v-icon></v-btn
                  >
                  <v-btn
                    small
                    color="orange"
                    class="btn-rodape"
                    title="Limpar"
                    :disabled="btnLimparDisabled"
                    @click="limpar()"
                    ><v-icon color="white">mdi-refresh</v-icon></v-btn
                  >
                  <v-btn
                    small
                    color="blue"
                    class="btn-rodape"
                    title="Inserir"
                    :disabled="btnNovoDisabled"
                    @click="limpar(), novo()"
                    ><v-icon color="white">mdi-plus</v-icon></v-btn
                  >
                  <v-btn
                    small
                    color="purple"
                    class="btn-rodape"
                    title="Buscar"
                    :disabled="btnNovoDisabled"
                    @click="
                      [(dialogBuscar = !dialogBuscar), (selectedRow = [])]
                    "
                    ><v-icon color="white">mdi-magnify</v-icon></v-btn
                  >
                  <v-btn
                    small
                    color="green"
                    class="btn-rodape"
                    title="Salvar"
                    :disabled="btnSalvarDisabled"
                    @click="exibirModalConfirmacao()"
                    ><v-icon color="white">mdi-content-save</v-icon></v-btn
                  >
                  <v-spacer />
                </div>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>

            <v-dialog
              v-model="dialogBuscar"
              width="500"
              transition="dialog-top-transition"
              style="background: white; min-height: 480px !important"
            >
              <v-card height="800">
                <v-toolbar color="blue">
                  <v-toolbar-title style="color: white"
                    >Buscar Internautas</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><v-icon color="red" @click="dialogBuscar = !dialogBuscar"
                    >mdi-close-circle</v-icon
                  >
                </v-toolbar>
                <v-card-text style="padding-top: 25px">
                  <v-text-field
                    v-model="txtBusca"
                    @keyup="buscar()"
                    label="Nome / Tel. / Email / CPF"
                    append-icon="mdi-magnify"
                    outlined
                  >
                  </v-text-field>
                  <v-data-table
                    v-model="selectedRow"
                    :items="internautas"
                    :headers="headers"
                    :single-select="true"
                    show-select
                    item-key="idinternauta"
                    locale="pt-BR"
                    mobile-breakpoint="600"
                    loading="5"
                    loading-text="Aguardando dados..."
                    no-data-text="Nenhum registro encontrado. Verifique..."
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="btn-rodape"
                    @click="
                      [
                        (dialogBuscar = !dialogBuscar),
                        montarObj(),
                        (internautas = []),
                        (txtBusca = ''),
                      ]
                    "
                    color="green"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Modal de confirmacao -->
            <v-dialog
              v-model="exibeModalConfirmacao"
              width="380"
              transition="dialog-top-transition"
              style="background: white; min-height: 380px !important"
            >
              <v-card>
                <v-toolbar color="orange"
                  ><v-icon color="white">mdi-exclamation</v-icon>
                  <v-toolbar-title style="color: white"
                    >Confirmação</v-toolbar-title
                  >
                  <v-spacer></v-spacer
                  ><v-icon
                    color="red"
                    @click="exibeModalConfirmacao = !exibeModalConfirmacao"
                    >mdi-close-circle</v-icon
                  ></v-toolbar
                >
                <v-card-text class="pa-4 mb-4 btns-rodape">
                  <strong>{{ mensagemConfirmacao }}</strong>

                  <div class="btns-rodape" style="padding-top: 20px">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="salvar()" small color="blue"
                      >Sim</v-btn
                    >
                    <v-btn
                      class="mr-2"
                      @click="exibeModalConfirmacao = !exibeModalConfirmacao"
                      small
                      color="orange"
                      >Não</v-btn
                    >
                    <v-spacer></v-spacer>
                  </div>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-dialog>

            <!--  -->
          </v-row>
        </v-form>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { cpf } from "cpf-cnpj-validator";
//import ModalConfirmaInternauta from '../components/modals/ModalConfirmaInternauta.vue';
export default {
  data() {
    return {
      btnCancelarDisabled: false,
      btnLimparDisabled: false,
      btnNovoDisabled: false,
      btnSalvarDisabled: false,
      valid: true,
      idinternauta: 0,
      nome: "",
      cpf: "",
      datanascimento: "",
      telefone: "",
      email: "",
      ativo: true,
      dialogBuscar: false,
      txtBusca: "",
      internautas: [],
      exibeModalConfirmacao: false,
      mensagemConfirmacao: "Confirma cadastro ou atualização?",
      senhaRadius: "",
      headers: [
        { text: "Nome", value: "nome", width: "100px" },
        { text: "E-mail", value: "email", width: "100px", sortable: false },
        { text: "CPF", value: "cpf", width: "100px" },
        { text: "Telefone", value: "telefone", width: "100px" },
        { text: "Ativo", value: "ativotxt", width: "50px" },
      ],
      selected: [],
      selectedRow: [],
      nameRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.length <= 150) ||
          "Campo deve ser igual ou menor que 150 caracteres",
      ],
      dateRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.length == 10) ||
          "Data deve ter formato DD/MM/AAAA",
      ],
      foneRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.replace(/[^a-z0-9]/gi, "").length >= 11) ||
          "Campo deve ser preenchido comnumero válido",
      ],
      FullNameRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.length <= 150) ||
          "Campo deve ser igual ou menor que 150 caracteres",
        (v) =>
          (v && v.trim().indexOf(" ") > 0) || "Nome completo(Nome e Sobrenome)",
      ],
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
      numerodocumentoRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (cpf.isValid(v) && v.replace(/[^a-z0-9]/gi, "").length == 11) ||
          "CPF inválido",
      ],
    };
  },
  components: {
    Footer,
    Navbar,
  },
  methods: {
    exibirModalConfirmacao() {
      if (!this.validate()) {
        return;
      }
      this.exibeModalConfirmacao = !this.exibeModalConfirmacao;
      if(this.idinternauta > 0){
        this.mensagemConfirmacao = "Confirma alteração de "+ this.nome.toUpperCase() + "?";
      }else{
        this.mensagemConfirmacao = "Confirma cadastro de "+ this.nome.toUpperCase() + "?";
      }
      
      console.log(this.exibeModalConfirmacao);
    },
    validate() {
      this.btn = this.$refs.form.validate();
      return this.$refs.form.validate();
    },
    clearNonMumeric(fieldValue, fieldName) {
      this[fieldName] = fieldValue.replace(/\D/g, "");
      return fieldValue;
    },
    idade() {
      if (this.datanascimento.length < 1) {
        return;
      }
      var d = new Date();
      this.ano_atual = d.getFullYear();
      this.mes_atual = d.getMonth() + 1;
      this.dia_atual = d.getDate();

      this.ano_aniversario = this.datanascimento.substr(0, 4);
      this.mes_aniversario = this.datanascimento.substr(5, 2);
      this.dia_aniversario = this.datanascimento.substr(9, 2);

      this.quantos_anos = this.ano_atual - this.ano_aniversario;

      if (
        this.mes_atual < this.mes_aniversario ||
        (this.mes_atual == this.mes_aniversario &&
          this.dia_atual < this.dia_aniversario)
      ) {
        this.quantos_anos--;
      }

      this.anosIdade = this.quantos_anos < 0 ? 0 : this.quantos_anos || 0;

      if (
        d.getDate(this.datanascimento) <= d.getDate(this.datacheckinprevisto) &&
        d.getDate(this.datanascimento) >= d.getDate(this.datacheckoutprevisto)
      ) {
        // this.isBirthDay = true
      }
    },
    limpar() {
      (this.nome = ""),
        (this.cpf = ""),
        (this.datanascimento = ""),
        (this.telefone = ""),
        (this.email = ""),
        (this.idinternauta = 0),
        (this.ativo = true);
    },
    montarObj() {
      this.nome = this.selectedRow[0].nome;

      //this.datanascimento = this.selectedRow[0].datanascimento;
      //formatar data recebida
      let dia = this.selectedRow[0].datanascimento.substr(8, this.selectedRow[0].datanascimento.length)
      let mes = this.selectedRow[0].datanascimento.substr(5,2)
      let ano = this.selectedRow[0].datanascimento.substr(0,4)
      this.datanascimento = dia + "/" + mes+ "/" + ano


      this.cpf = this.selectedRow[0].cpf;
      this.email = this.selectedRow[0].email;
      this.telefone = this.selectedRow[0].telefone;
      this.ativo = this.selectedRow[0].ativo;
      this.idinternauta = this.selectedRow[0].idinternauta;

      //alert('chamei')
    },
    novo() {
      (this.btnCancelarDisabled = false),
        (this.btnLimparDisabled = false),
        //this.btnNovoDisabled = false,
        (this.btnSalvarDisabled = false);
    },

    async salvar() {
      if (!this.validate()) {
        return;
      }
      //Formatar a data para insercao como date na chamada de API
      let dia = this.datanascimento.substr(0, 2)
      let mes = this.datanascimento.substr(3, 2)
      let ano = this.datanascimento.substr(6, this.datanascimento.length)

      let internauta = {
        nome: this.nome.toUpperCase(),
        datanascimento: ano +"-"+mes + "-" +dia,
        cpf: this.cpf.replace(/[^a-z0-9]/gi, ""),
        telefone: this.telefone,
        email: this.email.toLowerCase(),
        ativo: this.ativo,
      };
      if (this.idinternauta > 0) {
        //O ID FOI CARREGADO COM ALGUMA BUSCA ENTÃO, SERÁ ATUALIZADO DIRETAMENTE
        internauta.idinternauta = this.idinternauta;

        await this.$http
          .post("/auth/cadastrointernauta", internauta)
          .then(async (resp) => {
            console.log(resp.data);

            let bodyWhats = {
              to: "55" + this.telefone.replace(/[^a-z0-9]/gi, ""),
              body:
                "Sua senha de acesso é: " +
                resp.data.senharadius +
                " e seu usuário é o email informado no cadastro. Obrigado.",
            };
            console.log(bodyWhats);
            //alert(JSON.stringify(bodyWhats))
            this.msgLog = "7 Tentando enviar whatsapp(cadastro)";
            await this.enviaWhatsSenha(bodyWhats);
            
            this.msgLog = "7 Tentando enviar email(cadastro)";
            
            let bodyEmail = {
              destinatario: this.email,
              assunto: "Sua senha de acesso a internet",
              text: "Sua senha de acesso é: " + resp.data.senharadius + " e seu usuario é: " + resp.data.email,
              nomeusuario: "E-Spot",
            };

            await this.enviaEmailSenha(bodyEmail)            
            

            this.limpar();

            this.senhaRadius = resp.data.senharadius;

            console.log(resp.data);
            setTimeout(() => {
              this.mensagemConfirmacao = "OK! Processado com sucesso.";
              this.exibeModalConfirmacao = false;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await this.verificaCadastro(this.cpf).then(async (internautas) => {
          //alert(JSON.stringify(internautas));
          if (internautas.length > 0 && this.idinternauta == 0) {
            this.txtBusca = this.cpf;

            alert("Este CPF já foi cadastrado anteriormete. Verifique");
            this.exibeModalConfirmacao = false;
            this.dialogBuscar = true;
            this.buscar();
          } else {
            await this.verificaCadastro(this.email).then(
              async (internautas) => {
                //alert(JSON.stringify(internautas))
                if (internautas.length > 0 && this.idinternauta == 0) {
                  this.txtBusca = this.email;

                  alert(
                    "Este e-mail já foi cadastrado anteriormete. Verifique"
                  );

                  this.exibeModalConfirmacao = false;
                  this.dialogBuscar = true;
                  this.buscar();
                } else {
                  await this.$http
                    .post("/auth/cadastrointernauta", internauta)
                    .then(async (resp) => {
                      console.log(resp.data);

                      let bodyWhats = {
                        to: "55" + this.telefone.replace(/[^a-z0-9]/gi, ""),
                        body:
                          "Sua senha de acesso é: " +
                          resp.data.senharadius +
                          " e seu usuário é o email informado no cadastro. Obrigado.",
                      };
                      console.log(bodyWhats);
                      //alert(JSON.stringify(bodyWhats))
                      this.msgLog = "7 Tentando enviar whatsapp(cadastro)";
                      await this.enviaWhatsSenha(bodyWhats);

                      this.msgLog = "7 Tentando enviar email(cadastro)";
                      
                      let bodyEmail = {
                        destinatario: this.email,
                        assunto: "Sua senha de acesso a internet",
                        text: "Sua senha de acesso é: " + resp.data.senharadius + " e seu usuario é: " + resp.data.email,
                        nomeusuario: "E-Spot",
                      };

                      await this.enviaEmailSenha(bodyEmail)                      

                      this.limpar();

                      this.senhaRadius = resp.data.senharadius;

                      console.log(resp.data);
                      setTimeout(() => {
                        this.mensagemConfirmacao =
                          "OK! Processado com sucesso.";
                        this.exibeModalConfirmacao = false;
                      }, 1000);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            );
          }
        });
      }
    },
    async enviaWhatsSenha(body) {
      this.$http
        .post("/whatsapp/enviawhats", body)
        .then((resp) => {
          console.log(resp.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async enviaEmailSenha(body) {
      this.$http
        .post("/email/enviarEmail", body)
        .then((resp) => {
          console.log(resp.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },   

    buscar() {
      if (this.txtBusca.length < 3) {
        this.internautas = [];
        return;
      }

      this.$http("/auth/cadastrointernauta", {
        params: {
          param: this.txtBusca,
        },
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.internautas = resp.data;
            console.log(this.internautas);
          } else {
            this.internautas = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async verificaCadastro(valor) {
      return new Promise((resolve, reject) => {
        this.$http("/auth/cadastrointernauta", {
          params: {
            param: valor,
          },
        })
          .then((response) => resolve(response.data))
          .catch(() => reject);
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.btns-rodape {
  display: flex !important;
  justify-content: space-between;
  margin-trim: 5px;
}

.btn-rodape {
  margin-right: 5px;
  margin-top: 15px;
}
</style>