<template>
  <v-app>
    <v-main>
      <div style="heigth: 100%">
        <v-container
          fluid
          fill-height
          style="width: 100%; height: 100%; position: absolute"
        >
          <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
              <v-card elevation="10" style="border-radius: 8px">
                <v-card-title
                  class="pt-5"
                  style="background-color: rgb(29, 69, 150); color: white"
                  >Restauração de senha.</v-card-title
                >
                <v-card-text class="pt-4">
                  <div v-if="step == 1">
                    <v-text-field
                      type="email"
                      label="Seu email"
                      append-icon="mdi-email"
                      v-model="email"
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                    <v-row>
                      <v-spacer></v-spacer>
                      <div style="height: 50px; margin: 5px">
                        <v-col
                          ><span v-if="!emailExist" style="color: orange">
                            {{ emailResponse }}
                          </span></v-col
                        >
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-layout justify-center>
                        
                      <v-btn
                        v-bind:disabled="btnDisabled"
                        color="light-blue"
                        @click="buscarEmail"
                        style="color: white"
                        ><v-icon>mdi-file-find</v-icon>Buscar</v-btn
                      >
                      
                    </v-layout>
                  </div>
                  <div v-if="step == 2">
                    <v-row>
                      <v-col>
                        <span style="font-size: 16px; color: #1e90ff">
                          Enviaremos um email de recuperação para:
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col>
                       <span style="font-size: 15px; display:inline block"> {{ email }}</span>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <div style="height: 50px; margin: 5px">
                        <v-col
                          ><span v-if="!emailExist" style="color: orange">
                            {{ emailResponse }}
                          </span></v-col
                        >
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-layout justify-center>
                      <v-btn
                        color="light-blue"
                        @click="enviarEmail"
                        style="color: white"
                        ><v-icon>mdi-send</v-icon>OK</v-btn
                      >
                    </v-layout>
                  </div>
                  <div v-if="step == 3">
                    <v-row>
                      <v-col>
                        <span style="font-size: 16px; color: #1e90ff">
                          Enviamos um email. Confira sua caixa de entrada e/ou SPAM.
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col>
                       <span style="font-size: 15px; display:inline block"> {{ email }}</span>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <div style="height: 50px; margin: 5px">
                        <v-col
                          ><span style="color: orange">
                            {{ emailResponse }}
                          </span></v-col
                        >
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-layout justify-center>
                      <a href="/">
                      <v-btn v-if="step < 3"
                        color="light-blue"
                        @click="enviarEmail"
                        style="color: white"
                        ><v-icon>mdi-send</v-icon> OK</v-btn
                      ></a>
                    </v-layout>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
      emailExist: false,
      emailResponse: "",
      btnDisabled: true,
      step: 1,
      email: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if(pattern.test(value)){
              this.btnDisabled = false;
              return true
          }else{
              this.btnDisabled = true
              return  "E-mail inválido!"
          }
        }
      },
    };
  },
  components: {
    Footer,
  },
  methods: {

    buscarEmail() {
        this.$http("/auth/buscaremail", {
        params: { email: this.email },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.email) {
              this.email = response.data.email;
              this.step = 2;
              this.emailResponse = "OK";
            } else {
              this.emailResponse = "Email não encontrado. Verifique";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
    },

    async enviarEmail() {
      this.body = {
        email : this.email
      }
      await this.$http
        .post("auth/solicitaalteracaosenha", this.body, {
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          if (response.status == 200) {
              this.emailResponse = "OK. Email Enviado";
              //this.emailOK = true;
              this.step = 3
            setTimeout(() => {
              this.emailOK = false;
              window.location.href = '/'
            }, 3000);

          } else {
            //alert(response.data.error.response);
            //console.log(response.data.error.response)
          }
        })
        .catch((error) => {
          this.emailOK = true;
          setTimeout(function () {
            this.emailOK = false;
          }, 3000);
          console.log(error.response);

        });
    },
  },
};
</script>