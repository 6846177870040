<template>
  <v-app>
    <Navbar :drawer="false" />
    <v-main style="padding: 10px">
      <v-container style="padding-top: 80px">
        <v-form v-model="valid" lazy-validation ref="form">
          <v-row align="center" justify="center">
            <v-card style="width: 1000px" >
              <v-toolbar color="blue">
                <v-toolbar-title style="color: white">
                  <v-icon color="white">mdi-account-group-outline</v-icon>
                  Cadastre-se {{ msgLog }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <!-- <v-switch v-model="ativo" label="Ativo"></v-switch> -->
                <v-text-field
                  label="Nome Completo"
                  style="width: 600px"
                  :rules="FullNameRules"
                  v-model="nome"
                >
                </v-text-field>
                <v-text-field
                  label="CPF"
                  style="width: 300px"
                  v-model="cpf"
                  v-mask="'###.###.###-##'"
                  :rules="numerodocumentoRules"
                >
                </v-text-field>
                <v-text-field
                  label="Nascimento"
                  type="text"
                  v-mask="'##/##/####'"
                  placeholder="DD/MM/AAAA"
                  style="width: 300px"
                  v-model="datanascimento"
                  :rules="dateRules"
                >
                </v-text-field>
                <v-text-field
                  label="Celular"
                  v-mask="'(##)#####-####'"
                  style="width: 300px"
                  v-model="telefone"
                  placeholder="(XX)XXXXX-XXXX"
                  :rules="foneRules"
                >
                  <!-- @keyup="clearNonMumeric(telefone, 'telefone')" -->
                </v-text-field>
                <v-text-field
                  label="E-mail"
                  type="email"
                  style="width: 600px"
                  v-model="email"
                  :rules="emailRules"
                >
                </v-text-field>

                <div style="display:flex;height:50px;">
                  <div style="display:flex;align-items:center;justify-content:center;">
                    <!-- <v-checkbox v-model="aceite"></v-checkbox> -->
                    <v-switch
                    v-model="aceite"
                    ></v-switch>

                  </div>
                  <div style="display:flex;align-items:center;justify-content:center;">Li e Aceito os</div>
                  <div style="display:flex;align-items:center;justify-content:center;margin-left:5px;text-decoration:underline"><a @click="exibirTermo=true"><b>Termos da LGPD</b></a></div>
                </div>
                <!-- <v-text-field label="Senha" style="width: 600px" v-model="senha"> </v-text-field> -->

                <div class="btns-rodape mb-4">
                  <v-spacer />
                  <v-btn
                    small
                    color="red"
                    class="btn-rodape"
                    title="Cancelar"
                    :disabled="btnCancelarDisabled"
                    style="color: white"
                    @click="limpar()"
                    >Cancelar</v-btn
                  >
                  <!--        <v-btn
                    small
                    color="orange"
                    class="btn-rodape"
                    title="Limpar"
                    :disabled="btnLimparDisabled"
                    @click="limpar()"
                    style="color: white;"
                    >Limpar</v-btn
                  > -->
                  <!--                   <v-btn
                    small
                    color="blue"
                    class="btn-rodape"
                    title="Inserir"
                    :disabled="btnNovoDisabled"
                    @click="limpar(), novo()"
                    ><v-icon color="white">mdi-plus</v-icon></v-btn
                  > -->
                  <!--                   <v-btn
                    small
                    color="purple"
                    class="btn-rodape"
                    title="Buscar"
                    :disabled="btnNovoDisabled"
                    @click="
                      [(dialogBuscar = !dialogBuscar), (selectedRow = [])]
                    "
                    ><v-icon color="white">mdi-magnify</v-icon></v-btn
                  > -->
                  <v-btn
                    small
                    color="green"
                    class="btn-rodape"
                    title="Salvar"
                    :disabled="btnSalvarDisabled"
                    @click="salvar()"
                    style="color: white"
                    >OK</v-btn
                  >
                  <!-- 
                      exibirModalConfirmacao(
                        'Atenção, sua senha será enviada no email informado e/ou WhatsApp. Tenha certeza de tê-los informados corretamente.'
                      ) -->
                  <v-spacer />
                </div>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>

            <v-dialog
              v-model="dialogBuscar"
              width="500"
              transition="dialog-top-transition"
              style="background: white; min-height: 480px !important"
            >
              <v-card height="850">
                <v-toolbar color="blue">
                  <v-toolbar-title style="color: white"
                    >Buscar Internautas</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text style="padding-top: 8px">
                  <v-text-field
                    v-model="txtBusca"
                    @keyup="buscar()"
                    label="Nome / Tel. / Email / CPF"
                    append-icon="mdi-magnify"
                    outlined
                  >
                  </v-text-field>
                  <v-data-table
                    v-model="selectedRow"
                    :items="internautas"
                    :headers="headers"
                    :single-select="true"
                    show-select
                    item-key="idinternauta"
                    locale="pt-BR"
                    mobile-breakpoint="350"
                    loading="5"
                    loading-text="Aguardando dados..."
                    no-data-text="Nenhum registro encontrado. Verifique..."
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="btn-rodape"
                    @click="
                      [
                        (dialogBuscar = !dialogBuscar),
                        montarObj(),
                        (internautas = []),
                        (txtBusca = ''),
                      ]
                    "
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="exibirTermo"
              width="90%"
              transition="dialog-top-transition"
              style="background: white; min-height: 480px !important;"
            >
              <v-card height="auto">
                <v-toolbar color="blue">
                  <v-icon color="white">mdi-file-document</v-icon>
                  <v-toolbar-title style="color: white"
                    >Termo LGPD</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-icon @click="exibirTermo = false" color="red">mdi-close-circle</v-icon>
                </v-toolbar>
               
               <!-- <div v-html="termolgpd"  style="width:95%;min-height:60%;margin-left:2%;margin-top:2%;">
               </div> -->
              <pdf src="/files/termo_aceite_wifi_lgpd.pdf"
              v-for="i in numPages"
              :key="i"
              :page="i"
              ></pdf>
              <v-card-actions>
                <v-spacer></v-spacer>    
                <v-icon @click="exibirTermo = false" color="red">mdi-close-circle</v-icon>
              </v-card-actions>
              </v-card>
            </v-dialog>


            <!-- Modal de confirmacao -->
            <v-dialog
              v-model="exibeModalConfirmacao"
              width="380"
              transition="dialog-top-transition"
              style="background: white; min-height: 380px !important"
            >
              <v-card>
                <v-toolbar color="orange"
                  ><v-icon color="white">mdi-exclamation</v-icon>
                  <v-toolbar-title style="color: white"
                    >Confirmação</v-toolbar-title>
                  <v-spacer></v-spacer
                  ><v-icon
                    color="red"
                    @click="exibeModalConfirmacao = !exibeModalConfirmacao"
                    >mdi-close-circle</v-icon
                  ></v-toolbar
                >
                <v-card-text class="pa-4 mb-4 btns-rodape">
                  <strong>{{ mensagemConfirmacao }}</strong>

                  <div class="btns-rodape" style="padding-top: 20px">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="salvar()" small color="blue"
                      >Sim</v-btn
                    >
                    <v-btn
                      class="mr-2"
                      @click="exibeModalConfirmacao = !exibeModalConfirmacao"
                      small
                      color="orange"
                      >Não</v-btn
                    >
                    <v-spacer></v-spacer>
                  </div>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-dialog>

            <!--  -->
          </v-row>
        </v-form>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/NavBarPortal.vue";
import Footer from "@/components/Footer.vue";
import { cpf } from "cpf-cnpj-validator";
import pdf from 'vue-pdf'
export default {
  data() {
    return {
      numPages : 2,
      aceite : false,
      termolgpd:`<html><head></head><body>testes</body></html>`,
      exibirTermo:false,
      btnCancelarDisabled: false,
      btnLimparDisabled: false,
      btnNovoDisabled: false,
      btnSalvarDisabled: true,
      valid: true,
      idinternauta: 0,
      nome: "",
      cpf: "",
      datanascimento: "",
      telefone: "",
      email: "",
      ativo: true,
      dialogBuscar: false,
      txtBusca: "",
      internautas: [],
      mensagemConfirmacao: "",
      exibeModalConfirmacao: false,
      msgLog: "",
      ipRb: "10.0.0.1",
      headers: [
        { text: "Nome", value: "nome", width: "100px" },
        { text: "E-mail", value: "email", width: "100px", sortable: false },
        { text: "CPF", value: "cpf", width: "100px" },
        { text: "Telefone", value: "telefone", width: "100px" },
        { text: "Ativo", value: "ativotxt", width: "50px" },
      ],
      apiToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZHVzdWFyaW8iOjEsIm5vbWV1c3VhcmlvIjoiYWRtaW4iLCJzZW5oYSI6IjUxN2FiY2NkYzA0MmRmMzI0NDBmMTM0NTI1MTU4ZmQ0Iiwibm9tZSI6IkFkbWluIiwic29icmVub21lIjoiU2lzdGVtYSIsImVtYWlsIjoibWF1cmljaW9AZXNtZXJhbGRhcHJhaWFob3RlbC5jb20uYnIiLCJhdGl2byI6dHJ1ZSwiYWRtaW4iOnRydWUsInJlY3VwZXJhc2VuaGEiOnRydWUsImRhdGFjYWRhc3RybyI6IjIwMjEtMDItMTRUMDY6MTk6MDguMDAwWiIsInRva2VuIjoiNzcwZWEyMmYxM2I1NGZmM2RkNmJkY2JhMDUxODQ2ODQiLCJpYXQiOjE2MzEyODQ5MDB9.4jBoBbev5GDP68PUCYwsT1dF6cxU77BHcmapSNG0yqc",
      selected: [],
      selectedRow: [],
      nameRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.length <= 150) ||
          "Campo deve ser igual ou menor que 150 caracteres",
      ],
      dateRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) => (v && v.length == 10) || "Data deve ter formato DD/MM/AAAA",
      ],
      foneRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.replace(/[^a-z0-9]/gi, "").length >= 11) ||
          "Campo deve ser preenchido comnumero válido",
      ],
      FullNameRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          (v && v.length <= 150) ||
          "Campo deve ser igual ou menor que 150 caracteres",
        (v) =>
          (v && v.trim().indexOf(" ") > 0) || "Nome completo(Nome e Sobrenome)",
      ],
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
      numerodocumentoRules: [
        (v) => !!v || "Campo Obrigatório",
        //(v) => (v && v.length == 11) || "Campo deve ser igual ou menor que 20 caracteres",
        (v) =>
          (cpf.isValid(v) && v.replace(/[^a-z0-9]/gi, "").length == 11) ||
          "CPF inválido",
      ],
    };
  },
  components: {
    Footer,
    Navbar,
    pdf
  },
  methods: {
    getTermo(){
      this.$http("/empresa/termo", {headers: {"Content-Type": "application/json"}})
        .then((response) => {
          //alert(JSON.stringify(response.data))
            if (response.status == 200) {
              this.termolgpd = response.data.termo[0].termo;
            }
        }).catch((error) => {
          console.log(error);
        });
    },
    getLogo() {
      this.$http("/logo", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            //console.log(response.data)
            if (response.data.logoempresaprop.length > 0) {
              this.logoString = response.data.logoempresaprop;

              localStorage.setItem(
                "logoEspotEmpresaprop",
                response.data.logoempresaprop
              );
              this.logoString = response.data.logoempresaprop;
            } else {
              localStorage.setItem("logoEspotEmpresaprop", this.logoString);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exibirModalConfirmacao(msg) {
      if (!this.validate()) {
        return;
      }
      this.exibeModalConfirmacao = !this.exibeModalConfirmacao;
      this.mensagemConfirmacao = msg;
      console.log(this.exibeModalConfirmacao);
    },
    validate() {
      this.btn = this.$refs.form.validate();
      return this.$refs.form.validate();
    },
    clearNonMumeric(fieldValue, fieldName) {
      this[fieldName] = fieldValue.replace(/\D/g, "");
      return fieldValue;
    },
    idade() {
      if (this.datanascimento.length < 1) {
        return;
      }
      var d = new Date();
      this.ano_atual = d.getFullYear();
      this.mes_atual = d.getMonth() + 1;
      this.dia_atual = d.getDate();

      this.ano_aniversario = this.datanascimento.substr(0, 4);
      this.mes_aniversario = this.datanascimento.substr(5, 2);
      this.dia_aniversario = this.datanascimento.substr(9, 2);

      this.quantos_anos = this.ano_atual - this.ano_aniversario;

      if (
        this.mes_atual < this.mes_aniversario ||
        (this.mes_atual == this.mes_aniversario &&
          this.dia_atual < this.dia_aniversario)
      ) {
        this.quantos_anos--;
      }

      this.anosIdade = this.quantos_anos < 0 ? 0 : this.quantos_anos || 0;

      if (
        d.getDate(this.datanascimento) <= d.getDate(this.datacheckinprevisto) &&
        d.getDate(this.datanascimento) >= d.getDate(this.datacheckoutprevisto)
      ) {
        // this.isBirthDay = true
      }
    },
    limpar() {
      (this.nome = ""),
        (this.cpf = ""),
        (this.datanascimento = ""),
        (this.telefone = ""),
        (this.email = ""),
        (this.idinternauta = 0),
        (this.ativo = true);
    },
    montarObj() {
      this.nome = this.selectedRow[0].nome;
      this.datanascimento = this.selectedRow[0].datanascimento;
      this.cpf = this.selectedRow[0].cpf;
      this.email = this.selectedRow[0].email;
      this.telefone = this.selectedRow[0].telefone;
      this.ativo = this.selectedRow[0].ativo;
      this.idinternauta = this.selectedRow[0].idinternauta;

      //alert('chamei')
    },
    novo() {
      (this.btnCancelarDisabled = false),
        (this.btnLimparDisabled = false),
        //this.btnNovoDisabled = false,
        (this.btnSalvarDisabled = false);
    },
    async salvar() {
      this.msgLog = "1 Início Salvar";
      try {
        if (!this.validate()) {
          return;
        }

        this.msgLog = "2 Verificando o cadastro";
        //alert("Entrando na consulta");
        await this.consultaCadastroInternauta({
          campo: "cpf",
          valor: this.cpf,
        }).then(async (internautas) => {
          //alert(JSON.stringify(internautas));
          if (internautas.idinternauta > 0) {
            //alert("Encontrado CPF | PARANDO");

            let bodyWhats = {
              to: "55" + this.telefone.replace(/[^a-z0-9]/gi, ""),
              body:
                "Sua senha de acesso é: " +
                internautas.senharadius +
                " e seu usuário é o email informado no cadastro. Obrigado.",
            };
            console.log(bodyWhats);
            this.msgLog = "3 Tentando enviar msg whatsapp";
            await this.enviaWhatsSenha(bodyWhats);
            this.msgLog = "4 Tentando redirecionar";
            window.location.href =
              "http://" +
              this.ipRb +
              "/login?username=" +
              internautas.email +
              "&password=" +
              internautas.senharadius;
          } else {
            //console.log({campo: "email", valor: this.email})
            await this.consultaCadastroInternauta({
              campo: "email",
              valor: this.email.trim(),
            })
              .then(async (internautas) => {
                //alert(JSON.stringify(internautas));
                if (internautas.idinternauta > 0) {
                  //alert("Encontrado Email | PARANDO");
                  //alert(JSON.stringify(internautas));

                  let bodyWhats = {
                    to: "55" + this.telefone.replace(/[^a-z0-9]/gi, ""),
                    body:
                      "Sua senha de acesso é: " +
                      internautas.senharadius +
                      " e seu usuário é o email informado no cadastro. Obrigado.",
                  };
                  console.log(bodyWhats);
                  this.msgLog = "3 Tentando enviar msg whatsapp";
                  await this.enviaWhatsSenha(bodyWhats);
                                        
                  this.msgLog = "Tentando enviar Email";                      

                  let bodyEmail = {
                    destinatario: internautas.email,
                    assunto: "Sua senha de acesso a internet",
                    text: "Sua senha de acesso é: " + internautas.senharadius + " e seu usuario é: " + internautas.email,
                    nomeusuario: "E-Spot",
                  };

                  await this.enviaEmailSenha(bodyEmail)

                  this.msgLog = "4 Tentando redirecionar";
                  window.location.href =
                    "http://" +
                    this.ipRb +
                    "/login?username=" +
                    internautas.email +
                    "&password=" +
                    internautas.senharadius;
                } else {
                  console.log("cadastrar");
                  //alert("cadastrar");

                  //Formatar a data para insercao como date na chamada de API
                  let dia = this.datanascimento.substr(0, 2);
                  let mes = this.datanascimento.substr(3, 2);
                  let ano = this.datanascimento.substr(
                    6,
                    this.datanascimento.length
                  );

                  let internauta = {
                    nome: this.nome.toUpperCase(),
                    datanascimento: ano + "-" + mes + "-" + dia,
                    cpf: this.cpf.replace(/[^a-z0-9]/gi, ""),
                    telefone: this.telefone.replace(/[^a-z0-9]/gi, ""),
                    email: this.email.toLowerCase(),
                    ativo: this.ativo,
                    aceitelgpd : this.aceite
                  };
                  if (this.idinternauta > 0) {
                    internauta.idinternauta = this.idinternauta;
                  }
                  //alert("Pedido de pausa")
                  this.msgLog = "6 Chamando back-end a salvar";
                  await this.$http
                    .post("/auth/cadastrointernauta", internauta, {
                      headers: {
                        "x-access-token": this.apiToken,
                      },
                    })
                    .then(async (resp) => {
                      //alert(JSON.stringify(resp.data));
                      let bodyWhats = {
                        to: "55" + this.telefone.replace(/[^a-z0-9]/gi, ""),
                        body:
                          "Sua senha de acesso é: " +
                          resp.data.senharadius +
                          " e seu usuário é o email informado no cadastro. Obrigado.",
                      };
                      console.log(bodyWhats);
                      //alert(JSON.stringify(bodyWhats))
                      this.msgLog = "7 Tentando enviar whatsapp(cadastro)";
                      await this.enviaWhatsSenha(bodyWhats);

                      this.msgLog = "7 Tentando enviar email(cadastro)";
                      
                      let bodyEmail = {
                        destinatario: this.email,
                        assunto: "Sua senha de acesso a internet",
                        text: "Sua senha de acesso é: " + resp.data.senharadius + " e seu usuario é: " + resp.data.email,
                        nomeusuario: "E-Spot",
                      };

                      await this.enviaEmailSenha(bodyEmail)


                      window.location.href =
                        "http://" +
                        this.ipRb +
                        "/login?username=" +
                        resp.data.email +
                        "&password=" +
                        resp.data.senharadius;
                      // alert("Senha: " + resp.data.senharadius);
                      console.log(resp.data);
                      this.limpar();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    buscar() {
      if (this.txtBusca.length < 1) {
        this.internautas = [];
        return;
      }

      this.$http("/auth/cadastrointernauta", {
        params: {
          param: this.txtBusca,
        },
        headers: {
          "x-access-token": this.apiToken,
        },
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.internautas = resp.data;
            console.log(this.internautas);
          } else {
            this.internautas = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async consultaCadastroInternauta(obj) {
      console.log(JSON.stringify({ campo: obj.campo, valor: obj.valor }));
      return new Promise((resolve, reject) => {
        this.$http("/auth/consultacadastrointernauta", {
          params: {
            campo: obj.campo,
            valor: obj.valor,
          },
          headers: {
            "x-access-token": this.apiToken,
          },
        })
          .then((response) => resolve(response.data))
          .catch(() => reject);
      });
    },
    async enviaWhatsSenha(body) {
      this.$http
        .post("/whatsapp/enviawhats", body, {
          headers: {
            "x-access-token": this.apiToken,
          },
        })
        .then((resp) => {
          console.log(resp.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async enviaEmailSenha(body) {
      this.$http
        .post("/email/enviarEmail", body, {
          headers: {
            "x-access-token": this.apiToken,
          },
        })
        .then((resp) => {
          console.log(resp.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    
    aceite: function () {
      if(!this.aceite){
        this.btnSalvarDisabled = true
      }else{
        this.btnSalvarDisabled = false
      }
      
    }
  },
  mounted() {
    this.getLogo();
    this.getTermo();
  }
}
</script>
<style scoped>
.btns-rodape {
  display: flex !important;
  justify-content: space-between;
  margin-trim: 5px;
}

.btn-rodape {
  margin-right: 5px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
  margin-right: 15px;
}
</style>