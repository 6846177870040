<template>
  <v-app>
    <v-main>
      <div style="heigth: 100%">
        <v-container
          fluid
          fill-height
          style="width: 100%; height: 100%; position: absolute"
        >
          <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
              <v-card elevation="10" style="border-radius: 8px">
                <v-card-title
                  class="pt-5"
                  style="background-color: rgb(29, 69, 150); color: white"
                  >Restauração de senha.</v-card-title
                >
                <v-card-text class="pt-4">
                  <div v-if="isValid">
                    <v-text-field
                      type="text"
                      v-model="email"
                      readonly
                      label="Email"
                      append-icon="mdi-email"
                    ></v-text-field>

                    <v-text-field
                      type="password"
                      v-model="senha1"
                      label="Sua senha"
                      append-icon="mdi-account-key"
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      label="Confirme sua senha"
                      append-icon="mdi-account-key"
                      v-model="senha2"
                    ></v-text-field>
                    <v-row>
                      <v-spacer></v-spacer>
                      <div style="height: 120px; margin: 5px">
                        <v-col
                          ><span style="color: orange">
                            {{ senhaResponse }}
                          </span></v-col
                        >
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-layout justify-center>
                      <v-btn
                        color="light-blue"
                        @click="atualizaSenha"
                        style="color: white"
                        ><v-icon>mdi-tooltip-edit</v-icon>OK</v-btn
                      >
                    </v-layout>
                  </div>
                  <div v-if="!isValid">
                    <v-text-field
                      type="text"
                      v-model="email"
                      readonly
                      label="Email"
                      append-icon="mdi-email"
                    ></v-text-field>
                    <v-row>
                      <v-spacer></v-spacer>
                      <div style="height: 50px; margin: 5px">
                        <v-col
                          ><span style="color: orange">
                            {{ senhaResponse }}
                          </span></v-col
                        >
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-layout justify-center>
                      
                        <v-btn color="light-blue" style="color: white" @click="goHome"
                          ><v-icon>mdi-tooltip-edit</v-icon>OK</v-btn
                        >
                    </v-layout>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
      isValid: false,
      email: this.$route.query.email,
      token: this.$route.query.token,
      senha1: "",
      senha2: "",
      senhaOK: true,
      senhaResponse: "",
    };
  },
  components: {
    Footer,
  },
  methods: {
    goHome(){
      window.location.href = '/'
    },
    async atualizaSenha() {

      if (this.senha1.length < 6) {
        this.senhaResponse =
          "Sua senha não pode ser menor que 6 caractéres. \n Confira!";
        return;
      }

    if (this.senha1 != this.senha2) {
        this.senhaResponse =
          "Confirme sua senhas. Dados diferentes informados.";
        return;
      }

      this.body = {
            token: this.token,
            email: this.email,
            senha : this.senha1
      }
      await this.$http
        .post("/auth/atualizasenha", this.body, {
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          if (response.status == 200) {
              this.senha1 = ""
              this.senha2 = ""
              this.senhaResponse = "Senha alterada com sucesso"
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);
          } 
        })
        .catch((error) => {
          this.senhaResponse = "Erro na alteração de senha!"
          console.log(error.response);
        });
    },
  },
  created() {
    this.$http("/auth/buscaalteracaosenha", {
      params: { token: this.$route.query.token },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.email) {
            this.isValid = true;
          } else {
            this.senhaResponse = "Link inválido.";
            this.isValid = false;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>