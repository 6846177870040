<template>
  <div class="text-center">
    <v-row class="pa-2 d-flex m-2">
      <v-card width="100%" class="m-2 pa-2">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="statusuh"
              :items="descstatusuhs"
              item-value="descstatusuh"
              item-text="descstatusuh"
              label="Status da UH"
              style="width: 200px"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="bloco"
              :items="blocos"
              item-value="bloco"
              item-text="bloco"
              style="width: 200px"
              label="Bloco"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="statusgov"
              :items="descstatusgovs"
              item-value="descstatusgov"
              item-text="descstatusgov"
              label="Status da Gov."
              style="width: 200px"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="andar"
              :items="andares"
              item-text="andar"
              item-value="andar"
              style="width: 200px"
              label="Andar"
            ></v-select>
          </v-col>
          <v-col>
            <v-btn
              class="btn primary"
              small
              @click="loaduhData"
              style="margin-right: 10px; padding: 10px"
              >Pesquisar <v-icon small>mdi-filter</v-icon></v-btn
            >
            <v-btn
              class="btn error"
              small
              @click="reloadPage"
              style="margin-right: 10px; padding: 10px"
              >Limpar<v-icon small>mdi-reload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-col>
          <v-row>
            <div class="m-2"></div>
          </v-row>
        </v-col>
      </v-card>
    </v-row>

    <v-container class="pa-2">
      <v-layout wrap>
        <v-flex md1 v-for="uh in uhs" :key="uh.coduh" class="p-1">
          <v-card outline max-width="70">
            <v-card-title
              outline
              class="justify-center"
              style="white-space: nowrap"
              v-bind:class="{ success: !uh.numreserva, error: uh.numreserva }"
              >{{ uh.coduh }}</v-card-title
            >
            <v-card-subtitle
              class="justify-center"
              v-bind:class="{ success: !uh.numreserva, error: uh.numreserva }"
              >{{ uh.codreduzido }}</v-card-subtitle
            >
            <div>
              <span>{{ uh.descstatusuh }}</span> <br />
              <span>{{ uh.descstatusgov }}</span> <br />
            </div>
            <!-- 
             Limpa<br> -->
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uhs: [],
      descstatusuhs: [],
      descstatusgovs: [],
      andares: [],
      blocos: [],
      statusuh: "",
      statusgov: "",
      andar: "",
      bloco: "",
    };
  },
  created() {
    if (!localStorage.getItem("espottoken")) {
      window.location.href = "/";
      return;
    }

    this.loaduhData();

    this.$http("/ocupacao/buscastatusandarbloco", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("espottoken"),
      },
    })
      .then((response) => {
        if (response.status == 200) {
          this.descstatusgovs = response.data.descstatusgovs;
          this.descstatusuhs = response.data.descstatusuhs;
          this.andares = response.data.andares;
          this.blocos = response.data.blocos;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  },
  methods: {
    loaduhData() {
      this.$http("/dashboard/consultauhs", {
        params: {
          descstatusuh: this.statusuh,
          descstatusgov: this.statusgov,
          andar: this.andar,
          bloco: this.bloco,
        },
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              this.uhs = response.data;
            } else {
              this.uhs = [];
            }
          } else {
            this.uhs = [];
          }
        })
        .catch((error) => {
          window.location.href = "/";
          console.log(error);
          return;
        });
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>