<template>
  <v-app>
    <NavBar />
    <v-main style="margin-bottom: 15px;">
      <v-row class="m-2">
        <v-col>
          <v-spacer></v-spacer>
          <v-card class="pa-2 mb-2" max-width="400px">
            <v-card-title>
              <v-icon color="#008000">mdi-whatsapp</v-icon
              ><span style="color: #008000">WhatsApp</span>
            </v-card-title>
            <v-card-subtitle
              >Lista de transmissão para hóspedes checkin.</v-card-subtitle
            >
            <div v-if="chatapi.ativo">
            <v-textarea
              v-model="mensagemWhatsAppLista"
              prepend-icon="mdi-comment"
              class="pa-2"
              rows="8"
              no-resize
              :counter="maxChars"
              label="WhatsApp"
              style="background-color: #dcdcdc; border-radius: 5px; max-width: 380px;"
              @keyup="contaChars"
            >
            </v-textarea>
            <v-row>
              <v-spacer></v-spacer>
              <div style="height: 50px">
                <v-col
                  ><span v-if="WhatsProc">{{ responseEnvioWhats }}</span></v-col
                >
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-card-actions>
              <v-btn color="green" small @click="enviaMensagemLista">
                <v-icon small>mdi-send</v-icon>
                Enviar
              </v-btn>
              <v-btn color="orange" small @click="mensagemWhatsAppLista = ''">
                <v-icon small>mdi-close</v-icon>
                Limpar
              </v-btn>
              <!--             <v-btn>
              Clique
            </v-btn> -->
            </v-card-actions>
            </div>
          </v-card>
          <v-spacer></v-spacer>
          <AgendaWhatsApp :chatapi="this.chatapi" />
        </v-col>
        
        <!-- Segundo card -->
        <v-col>
          <v-spacer></v-spacer>
          <v-card class="pa-2 mb-2" max-width="700px">
            <v-card-title>
              <v-icon color="#008000">mdi-whatsapp</v-icon
              ><span style="color: #008000">WhatsApp</span>
            </v-card-title>
            <v-card-subtitle>Mensagens diretas ao hóspede.</v-card-subtitle>
            <div v-if="chatapi.permitenotificacaoestadia">
            <v-row class="m-2 pa-2">
              <v-text-field
                v-model="nome"
                label="Nome || Sobrenome"
                append-icon="mdi-account"
                style="max-width: 150px"
                @keyup="
                  (numReserva = '/'),
                    (coduh = '/'),
                    (telefone = '/'),
                    (selectedRow = []),
                    buscaHospedes()
                "
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="coduh"
                label="Nº Apartamento"
                append-icon="mdi-domain"
                style="max-width: 150px"
                @keyup="
                  (numReserva = '/'),
                    (nome = '/'),
                    (telefone = '/'),
                    (selectedRow = []),
                    buscaHospedes()
                "
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="numReserva"
                label="Reserva"
                append-icon="mdi-domain"
                style="max-width: 150px"
                @keyup="
                  (coduh = '/'),
                    (nome = '/'),
                    (telefone = '/'),
                    (selectedRow = []),
                    buscaHospedes()
                "
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="telefone"
                label="Tel (DDI|DDD|Telefone)"
                append-icon="mdi-phone"
                style="max-width: 150px"
                @keyup="
                  (coduh = '/'),
                    (nome = '/'),
                    (numReserva = '/'),
                    (selectedRow = []),
                    buscaHospedes()
                "
              ></v-text-field>
              <v-data-table
                v-model="selectedRow"
                :headers="headers"
                :items="resultSet"
                :items-per-page="5"
                class="elevation-1"
                show-select
                :single-select="singleSelect"
                item-key="idcontatos"
              >
                <template v-slot:top>
                  <v-switch
                    v-model="singleSelect"
                    label="Seleção única"
                    class="pa-3"
                  ></v-switch> </template
              ></v-data-table>

              <v-spacer></v-spacer>
            </v-row>
            <v-row class="mb-2 justify-space-around" no-gutters>
              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nome</th>
                        <th class="text-left">UH</th>
                        <th class="text-left">Telefone</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in selectedRow" :key="item.name">
                        <td>{{ item.nome }} {{ item.sobrenome }}</td>
                        <td>{{ item.coduh }}</td>
                        <td>
                          {{ item.ddi }}{{ item.ddd }}{{ item.numerotelefone }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-row>
            <v-textarea
              v-model="mensagemDiretaWhatsApp"
              prepend-icon="mdi-comment"
              rows="8"
              no-resize
              :counter="maxChars"
              label="WhatsApp"
              class="pa-2 m-2"
              style="background-color: #dcdcdc; border-radius: 5px; max-width: 550px;"
              @keyup="contaChars"
            >
            </v-textarea>
            <v-row>
              <v-spacer></v-spacer>
              <div style="height: 50px">
                <v-col
                  ><span v-if="WhatsProc">{{ responseEnvioWhats }}</span></v-col
                >
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-card-actions>
              <v-btn color="green" small @click="enviaMensagem">
                <v-icon small>mdi-send</v-icon>
                Enviar
              </v-btn>
              <v-btn color="orange" small @click="mensagemDiretaWhatsApp = ''">
                <v-icon small>mdi-close</v-icon>
                Limpar
              </v-btn>
              <!--             <v-btn>
              Clique
            </v-btn> -->
            </v-card-actions>
            </div>
          </v-card>
          <v-spacer></v-spacer>
          
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
import AgendaWhatsApp from "@/components/AgendaWhatsApp.vue";
export default {
  data() {
    return {
      singleSelect: true,
      maxChars: 500,
      chatapi: {
        apiurl: "",
        ativo: false,
        horaenviomensagemdiario: "00:00:00",
        idwhatsappchatapi: 0,
        instanceid: "0",
        mensagemdiariapadrao: "",
        notificacaodiaria: false,
        permitenotificacaoestadia: false,
        token: "",
      },
      selectedRow: [],
      i: 0,
      headers: [
        {
          text: "UH",
          align: "start",
          sortable: false,
          value: "coduh",
        },
        { text: "Nome", value: "nome" },
        { text: "Sobrenome", value: "sobrenome" },
        { text: "DDI", value: "ddi" },
        { text: "DDD", value: "ddd" },
        { text: "Telefone", value: "numerotelefone" },
        { text: "Reserva", value: "numreserva" },
      ],
      nome: "",
      coduh: "",
      numReserva: "",
      telefone: "",
      resultSet: [],
      mensagemWhatsAppLista: "",
      mensagemDiretaWhatsApp: "",
      nomeUsuario: localStorage.getItem("nomeUsuarioEspot"),
      WhatsProc: false,
      WhatsProcDir: false,
      responseEnvioWhats: "",
    };
  },
  components: {
    NavBar,
    Footer,
    AgendaWhatsApp,
  },
  methods: {
    buscaChatApi() {
      this.$http("/whatsapp/buscachatapi", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.apiurl) {
              this.chatapi.ativo = response.data.ativo;
              this.chatapi.apiurl = response.data.apiurl;
              this.chatapi.token = response.data.token;
              this.chatapi.permitenotificacaoestadia =
                response.data.permitenotificacaoestadia;
              this.chatapi.notificacaodiaria = response.data.notificacaodiaria;
              this.chatapi.mensagemdiariapadrao =
                response.data.mensagemdiariapadrao;
              this.chatapi.horaenviomensagemdiario =
                response.data.horaenviomensagemdiario;
              this.chatapi.idwhatsappchatapi = response.data.idwhatsappchatapi;
              //console.log(this.chatapi);
            }
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    contaChars() {
      if (this.mensagemWhatsAppLista.length > this.maxChars) {
        this.mensagemWhatsAppLista = this.mensagemWhatsAppLista.substr(
          0,
          this.maxChars
        );
      }
    },
    enviaMensagemLista() {
      if (!this.mensagemWhatsAppLista.length > 0) {
        return;
      } else {
        this.$http
          .post(
            "/whatsapp/envialistawhats",
            { mensagem: this.mensagemWhatsAppLista },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("espottoken"),
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.responseEnvioWhats = "Mensagens sendo enviadas.";
              this.WhatsProc = true;
              setTimeout(() => {
                this.WhatsProc = false;
              }, 3000);
              console.log(response.data.message);
            } else {
              //alert(response.data.error.response);
              //console.log(response.data.error.response)
            }
          })
          .catch((error) => {
            this.responseEnvioWhats = "Erros enviando as mensagens";
            this.WhatsProc = true;
            setTimeout(function () {
              this.WhatsProc = false;
            }, 3000);
            console.log(error.response);
          });
      }
    },

    enviaMensagem() {
      if (
        this.mensagemDiretaWhatsApp.length < 1 ||
        this.selectedRow.length < 1
      ) {
        return;
      } else {
        for (this.i = 0; this.i < this.selectedRow.length; this.i++) {
          console.log(
            this.selectedRow[this.i].ddi +
              this.selectedRow[this.i].ddd +
              this.selectedRow[this.i].numerotelefone
          );
        }
        this.$http
          .post(
            "/whatsapp/enviawhats",
            {
              /*to : this.selectedRow[this.i].ddi+this.selectedRow[this.i].ddd+this.selectedRow[this.i].numerotelefone,*/
              to: "5584987012966",
              body: this.mensagemDiretaWhatsApp,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("espottoken"),
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.responseEnvioWhats = "Mensagens sendo enviadas.";
              this.WhatsProc = true;
              setTimeout(() => {
                this.WhatsProcDir = false;
              }, 3000);
              console.log(response.data.message);
            } else {
              //alert(response.data.error.response);
              //console.log(response.data.error.response)
            }
          })
          .catch((error) => {
            this.responseEnvioWhats = "Erros enviando as mensagens";
            this.WhatsProc = true;
            setTimeout(function () {
              this.WhatsProc = false;
            }, 3000);
            console.log(error.response);
          });
      }
    },

    buscaHospedes() {
      this.$http("/ocupacao/buscahospedes", {
        params: {
          nome: this.nome,
          numreserva: this.numReserva,
          coduh: this.coduh,
          telefone: this.telefone,
        },
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.resultSet = response.data;
            //console.log(response.data);
            //console.log(this.nome);
          } else {
            this.resultSet = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (!localStorage.getItem("espottoken")) {
      window.location.href = "/";
    }
    this.buscaChatApi();
  },
};
</script>