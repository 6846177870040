<template>
  <v-app>
    <v-container style="margin-bottom: 15px;">
      <Navbar :drawer="false" />
      <v-main style="margin-bottom: 15px;">
        <v-card class="p-2 m-2">
          <v-card-title>Cadastros de Usuários</v-card-title>
          <v-form>
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  label="Nome"
                  style="width: 200px"
                  v-model="nome"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="text"
                  label="Sobrenome"
                  style="width: 200px"
                  v-model="sobrenome"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="email"
                  label="E-mail"
                  style="width: 200px"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  label="Login"
                  style="width: 200px"
                  v-model="nomeusuario"
                  :rules="[rules.required]"
                  v-bind:disabled="isUpdate"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="password"
                  label="Senha"
                  style="width: 200px"
                  v-model="senha"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="password"
                  label="Confirmação de Senha"
                  style="width: 200px"
                  v-model="senha2"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>

              <v-spacer></v-spacer>
              <v-col><v-switch class="m-2" v-model="admin" label="Admin"> </v-switch></v-col>
              <v-col>
                
                <v-switch class="m-2" v-model="ativo" label="Ativo"> </v-switch>
              </v-col>

              <v-col>
                <v-btn
                  v-if="!isUpdate"
                  class="m-2"
                  small
                  color="green"
                  @click="cadastroUsuario"
                  ><v-icon>mdi-plus</v-icon>OK</v-btn
                >
                <v-btn
                  v-if="isUpdate"
                  class="m-2"
                  small
                  color="orange"
                  @click="atualizaUsuario"
                  ><v-icon>mdi-refresh</v-icon> OK</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="m-2">
              <v-spacer></v-spacer>
              <v-btn
                class="m-2"
                small
                color="#ff4040"
                @click="reloadPage"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-row>
          </v-form>
        </v-card>
        <ListaUsuarios @sobeUsuario="loadDataUser" />
      </v-main>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import ListaUsuarios from "@/components/ListaUsuarios.vue";
export default {
  data() {
    return {
      btnDisabled: true,
      isUpdate: false,
      idusuario: 0,
      ativo : true,
      usuarioObj: {},
      nome: "",
      sobrenome: "",
      email: "",
      nomeusuario: "",
      senha: "",
      senha2: "",
      admin: false,
      usuario: {},
      lista: true,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 caracteres",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (pattern.test(value)) {
            this.btnDisabled = false;
            return true;
          } else {
            this.btnDisabled = true;
            return "E-mail inválido!";
          }
        },
      },
    };
  },
  components: {
    Footer,
    Navbar,
    ListaUsuarios,
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    loadDataUser(usuario) {
      this.ativo = usuario.ativo
      this.idusuario = usuario.idusuario;
      this.nome = usuario.nome;
      this.sobrenome = usuario.sobrenome;
      this.nomeusuario = usuario.nomeusuario;
      this.email = usuario.email;
      this.admin = usuario.admin;
      this.isUpdate = true;

    },

    reloadComp() {
      this.lista = false;
      setTimeout(() => {
        this.lista = true;
      }, 1000);
    },

    atualizaUsuario() {
      if (
        this.nome.length < 1 ||
        this.sobrenome.length < 1 ||
        this.nomeusuario.length < 1 ||
        this.email.length < 1
      ) {
        alert("Verifique");
        return;
      }
      this.usuarioObj = {
        idusuario: this.idusuario,
        nome: this.nome.trim(),
        sobrenome: this.sobrenome.trim(),
        email: this.email.trim(),
        nomeusuario: this.nomeusuario.trim(),
        admin: this.admin,
        ativo : this.ativo
      };

      if (this.senha.length > 0 && this.senha2.length > 0) {
        if (this.senha != this.senha2) {
          alert("Senhas não conferem");
          return;
        } else {
          this.usuarioObj.senha = this.senha.trim();
        }
      }

      this.$http
        .put("/auth/cadastrausuario", this.usuarioObj, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            document.location.reload();
          } else {
            alert("Erro ao atualizar o usuário.");
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.getItem("espotportaltoken");
        });
    },
    cadastroUsuario() {
      if (
        this.nome.length < 1 ||
        this.sobrenome.length < 1 ||
        this.nomeusuario.length < 1 ||
        this.email.length < 1 ||
        this.senha.length < 1 ||
        this.senha2.length < 1
      ) {
        alert("Verifique");
        return;
      }

      if (this.senha != this.senha2) {
        alert("Senhas não conferem");
        return;
      }

      this.usuarioObj = {
        nome: this.nome.trim(),
        sobrenome: this.sobrenome.trim(),
        email: this.email.trim(),
        nomeusuario: this.nomeusuario.trim(),
        senha: this.senha.trim(),
        admin: this.admin,
        ativo : this.ativo
      };

      this.$http
        .post("/auth/cadastrausuario", this.usuarioObj, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            document.location.reload();
          } else {
            alert("Erro ao cadastrar o usuário.");
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.getItem("espotportaltoken");
        });
    },
  },
  created() {
    
    if(!localStorage.getItem('espottoken'))  window.location.href = '/'
  
  },
};
</script>