import Vue from 'vue'
import axios from 'axios'

//axios.defaults.baseURL = 'http://10.125.121.117:8000'
axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:8000' : 'https://apihccardio.economysoftware.com.br'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const token = localStorage.getItem('espottoken')
if (token) {
    axios.defaults.headers.common['x-access-token'] = token
}

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})