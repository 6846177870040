<template>
  <v-app>
    <v-container>
      <Navbar :drawer="false" />
      <v-main style="margin-bottom: 15px;">
        <v-row align="center" class="mb-2" >
          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2" v-if="isHotel">
              <v-list-item three-line>
                <v-list-item-avatar color="green"
                  ><v-icon large>mdi-domain</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Ocupação</div>
                  <v-list-item-title class="headline mb-1">
                    {{ this.percocupacao }}%
                  </v-list-item-title>
                  <v-list-item-subtitle> Atual </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a href="/ocupacaoanalitico">
                  <v-icon>mdi-dots-horizontal</v-icon></a
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2" v-if="isHotel">
              <v-list-item three-line>
                <v-list-item-avatar color="blue"
                  ><v-icon large>mdi-home</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Hospedagem</div>
                  <v-list-item-title class="headline mb-1">
                    {{ this.totalhospedes }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Hóspedes </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a href="/hospedes"> <v-icon>mdi-dots-horizontal</v-icon></a>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="yellow">
                  <i class="icon icon-emoji" style="font-size: 24px"></i>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Fale Conosco</div>
                  <v-list-item-title class="headline mb-1">
                    {{ totalmensagens }} / {{ medianotamensagens }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >Mens. / Média
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a href="/faleconosco/mensagens">
                  <v-icon>mdi-dots-horizontal</v-icon></a
                >
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="orange">
                  <v-icon large>mdi-antenna</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">E-Spot</div>
                  <v-list-item-title class="headline mb-1">
                    {{totalsessoesativas}}
                  </v-list-item-title>
                  <v-list-item-subtitle> Sessões ativas </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a href="#">
                  <v-icon>mdi-dots-horizontal</v-icon></a
                >
              </v-card-actions>
            </v-card>
          </v-col>


          <v-col>
            <v-card class="mx-auto" width="400" height="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="orange">
                  <v-icon large>mdi-ranking</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Ranking Internauta</div>
                  <v-list-item-subtitle> Top 3 </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <div style="height:100%;display:flex;flex-direction:column;padding:20px;">
                <div v-for="position in ranking" :key="position.username" style="display:flex;"><div style="width:10%;"><b>{{position.posicao}}ª</b>:</div><div  style="width:80%;"> {{position.username}}</div><div style="width:10%;"> {{position.qtde}}</div></div>
              </div>
              
            </v-card>
          </v-col>

          <DashboardHomeHotel v-if="isHotel"/>
        </v-row>
      </v-main>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import DashboardHomeHotel from "@/components/DashboardHomeHotel.vue";
export default {
  name: "App",
  data() {
    let data = new Date();
    let mm = "";
    if (data.getMonth() < 10) {
      mm = "0" + (data.getMonth() + 1);
    } else {
      mm = data.getMonth() + 1;
    }
    return {
      drawer: false,
      percocupacao: "",
      uhsocupadas: "",
      uhspool: "",
      totalhospedes: "",
      totalmensagens: 0,
      totalsessoesativas:0,
      ranking:[],
      contadorRanking:1,
      medianotamensagens: 0,
      dataini: data.getFullYear() + "-" + mm + "-" + data.getDate(),
      datafim: data.getFullYear() + "-" + mm + "-" + data.getDate(),
      isHotel : true
    };
  },
  components: {
    Footer,
    Navbar,
    DashboardHomeHotel,
  },
  methods: {
    atualizaDadosTela() {
      this.$http("/dashboard/buscatotalhospedes", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.totalhospedes = response.data.totalhospedes;
          }
        })
        .catch((error) => {
          window.location.href = "/";
          console.log(error);
        });

      this.$http("/portal/buscamensagens", {
        params: { dataini: this.dataini, datafim: this.datafim },
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.totalmensagens = response.data.total;
          this.medianotamensagens =
            parseFloat(response.data.media).toFixed(1) || 0;
        }
      });

      this.$http("/dashboard/consultaocupacao", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.uhspool = response.data.uhspool;
          this.uhsocupadas = response.data.uhsocupadas;
          this.percocupacao = (this.uhsocupadas * 100) / this.uhspool;
          this.percocupacao = parseFloat(this.percocupacao).toFixed(1);
        }
      });

      this.$http("/dashboard/consultasessoesativas", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.totalsessoesativas = response.data.qtde;
        }
      });

      this.$http("/dashboard/consultarrankingnavegacao", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.contadorRanking = 1
          this.ranking = response.data;
          for(var i=0,len = this.ranking.length;i<len;i++){
            this.ranking[i].posicao = i+1
          }
        }
      });

    },
    verificaTipoCliente() {
      /*  VERIFICA O TIPO CLIENTE CADASTRADO NA BASE SE É OU NÃO MEIO DE HOSPEDAGEM  */
      this.isHotel = localStorage.getItem('meiodehospedagem') === 'true' ? true : false
    }
  },
  mounted (){
    this.verificaTipoCliente()
  },

  created() {
    this.atualizaDadosTela();
    setInterval(() => {
      location.reload();
    }, 360000);
  },
};
</script>
<style scoped>

</style>