<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-card elevation="3" height="auto" class="pa-2" app>
        <v-card-title> Cadastro de Avaliações </v-card-title>
        <br />
        <v-row class="pa-2">
          <v-col>
            <v-text-field
              clearable
              outlined
              style="max-heigth: 350px; size: 200; width: 750px"
              label="Texto da avaliação."
              prepend-icon="mdi-comment"
              counter="200"
              id="textoAval"
              v-model="textoAval"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-2">
          <v-col>
            <v-radio-group>
              <v-radio
                v-for="periodo in periodosavaliacao"
                :key="periodo.idperiodoavaliacao"
                :label="periodo.descperiodo"
                :value="periodo.idperiodoavaliacao"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-radio-group>
              <v-radio
                v-for="idioma in idiomas"
                :key="idioma.ididioma"
                :label="idioma.traducaodesc"
                :value="idioma.ididioma"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-container class="px-0" fluid>
              <v-switch label="Incluir Tradução"></v-switch>
            </v-container>
            <v-btn small color="green" @click="validaQtdeChars" class="mr-2"
              >Salvar</v-btn
            >
            <v-btn small color="orange" @click="textoAval = ''">Limpar</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-container class="grey lighten-5 mb-6 mt-6">
        <v-row no-gutters>
          <v-col
            v-for="pergunta in perguntasavaliacao"
            :key="pergunta.idperguntaavaliacao"
            class="pa-2 m-2"
          >
            <v-card class="pa-2 ml-2" outlined tile
              ><v-card-subtitle style="color: #000;" >{{ pergunta.traducaodesc }}</v-card-subtitle>
              <v-textarea 
                :value="pergunta.textopergunta" 
                class="mx-2"
                readonly 
                no-resize
                rows="1"
                prepend-icon="mdi-comment"> </v-textarea>
              <v-card-actions>
                <v-icon small>mdi-clock</v-icon>
                <span class="justify-center p-2 m-2">{{
                  pergunta.descperiodo
                }}</span>
                <v-btn class="m-2 primary" x-small>Editar</v-btn>
                <v-btn class="m-2 error" x-small>Excluir</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
      textoAval: "",
      idiomas: [],
      periodosavaliacao: [],
      perguntasavaliacao: [],
    };
  },
  components: {
    NavBar,
    Footer,
  },
  methods: {
    validaQtdeChars() {
      if (this.textoAval.length <= 200 && this.textoAval.length > 0) {
        alert("Ok, o texto pode ser postado.");
      } else {
        alert("Texto atende os parâmetros mínimos obrigatórios. Confira!");
      }
    },
  },
  created() {
    this.$http("/idiomas/buscaidiomas", {
      headers: { "Content-Type": "application/json", "x-access-token" : localStorage.getItem('espottoken') }
    }).then((response) => {
      if (response.status == 200) {
        this.idiomas = response.data;
        console.log(this.idiomas);
      } else {
        console.log(response.status);
      }
    });

    this.$http("/avaliacao/buscaperiodosavaliacao", {
      headers: { "Content-Type": "application/json", "x-access-token" : localStorage.getItem('espottoken') }
    }).then((response) => {
      if (response.status == 200) {
        this.periodosavaliacao = response.data;
        console.log(this.periodosavaliacao);
      } else {
        console.log(response.status);
      }
    });

    this.$http("/avaliacao/buscaperguntasavaliacao", {
      headers: { "Content-Type": "application/json", "x-access-token" : localStorage.getItem('espottoken') }
    }).then((response) => {
      if (response.status == 200) {
        this.perguntasavaliacao = response.data;
        console.log(this.perguntasavaliacao);
      } else {
        console.log(response.status);
      }
    });
  },
};
</script>
<style scoped>
</style>