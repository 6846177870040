<template>
  <v-app>
    <v-main>
      <Navbar :drawer="false" />
      <v-container fluid>
        <!-- Filtro -->
        <v-row>
          <v-col class="d-flex" cols="12">
            <v-card width="100%">
              <v-card-title>
                Escolha o relatório e o filtro desejado:
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="selectedReport"
                  :items="reports"
                  label="Relatório"
                  item-text="titulo"
                  item-value="id"
                  return-object
                  @change="reset()"
                ></v-select>
                <v-row v-if="showFilters">
                  <v-col>
                    <v-text-field v-model="username" label="User Name (email)">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="callingstationid"
                      label="Mac Addr. (Calling Station Id)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="framedipaddress"
                      label="IP (Framed IP Address)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn class="btn primary" @click="pesquisar()">
                  Pesquisar
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- Dados -->
        <v-row>
          <v-col class="d-flex" cols="12">
            <v-card width="100%">
              <v-card-text>
                <v-data-table
                  :headers="reportHeaders"
                  :items="reportData"
                  :items-per-page="5"
                  class="elevation-1"
                  no-data-text="Nenhum registro encontrado."
                ></v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <div class="text-center" style="padding-bottom: 15px">
                  <v-btn
                    title="Baixar arquivo excel."
                    :disabled="!pesquisarAtivado || reportData.length == 0"
                  >
                    <obj-array-to-excel
                      :titulo="selectedReport.titulo"
                      :downloadData="reportData"
                      :headers="reportHeaders"
                    ></obj-array-to-excel>
                  </v-btn>
                </div>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import ObjArrayToExcel from "../components/ObjArrayToExcel.vue";

export default {
  components: {
    Footer,
    Navbar,
    ObjArrayToExcel,
  },
  data: () => ({
    reports: [],
    selectedReport: {},
    reportData: [],
    reportHeaders: [],
    pesquisarAtivado: true,
    usuarios: [],
    username: "",
    callingstationid: "",
    framedipaddress: "",
    showFilters: true,
  }),
  methods: {
    reset() {
      this.reportData = [];
      this.reportHeaders = [];
      this.username = "";
      this.callingstationid = "";
      this.framedipaddress = "";
    },
    pesquisar() {
      if (this.selectedReport.id != 2) {
        this.reportHeaders = [];
        this.reportData = [];
        this.pesquisarAtivado = false;
        this.$http
          .post("/relatorio/select", {
            id: this.selectedReport.id,
            username: this.username.trim(),
            callingstationid: this.callingstationid.trim(),
            framedipaddress: this.framedipaddress.trim(),
          })
          .then((response) => {
            this.pesquisarAtivado = true;
            if (response.status == 200) {
              var resp = response.data;
              this.reportHeaders = resp.headers;
              this.reportData = resp.data;
            }
          })
          .catch((e) => {
            this.pesquisarAtivado = true;
            console.error(e);
          });
      } else if (this.selectedReport.id == 2) {
          this.$http('/relatorio/select/sitesporhora' )
            .then(response =>{
            this.pesquisarAtivado = true;
            if (response.status == 200) {
              var resp = response.data;
              this.reportHeaders = resp.headers;
              this.reportData = resp.data;
            }
            }).catch(error=>{
                console.error(error)
            })
      }
    },
    loadReports() {
      this.$http
        .post("/relatorio/todos", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          this.reports = response.data;
        })
        .catch((e) => console.error(e));
    },
  },
  watch: {
    selectedReport: function () {
      if (this.selectedReport.id == 2) {
        this.showFilters = false;
      } else {
        this.showFilters = true;
      }
    },
  },
  created() {
    this.loadReports();
  },
  mounted() {},
};
</script>