<template>
  <v-card class="p-2">
    <v-card-title>
      <v-text-field
        v-model="selected"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table 
      v-model="selectedRow"
      :headers="headers"
      :items="usuarios"
      :search="search"
      :single-select="true"
      @click:row="this.$emit('sobeUsuario', this.selectedRow[0])"
      show-select
      item-key="idusuario"
      locale="pt-BR"
      mobile-breakpoint="350"
      loading="20"
      loading-text="Carregando.."
    >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      selected: [],
      selectedRow :[],
      headers: [
        {
          text: "Login",
          align: "start",
          filterable: true,
          width: "10px",
          value: "nomeusuario",
        },
        { text: "Nome", value: "nome", width: "80px" },
        { text: "Sobrenome", value: "sobrenome", width: "80px" },
        { text: "E-mail", value: "email", width: "150px" },
        { text: "Admin", value: "admintxt", width: "50px" },
        { text: "Ativo", value: "ativotxt", width: "50px" },
        { text: "Data Cadastro", value: "datacadastro" , width: "100px" }
      ],
      usuarios: [],
    };
  },
  watch:{
    selectedRow: function (){
      this.$emit('sobeUsuario', this.selectedRow[0])
    }
  },
  created(){

    if(!localStorage.getItem("espottoken")){
      window.location.href = "/";
      return
    }
      this.$http("/auth/listausuarios", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.usuarios = response.data;
          //console.log(this.desserts)
        }
      }).catch(error =>{
          console.log(error)
      })
  }
}



</script>