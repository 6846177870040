<template>
  <v-app>
    <v-main>
      <v-container>
        <NavBar :drawer="false" />
        <v-card md6 class="mx-auto">
          <v-card-title>Painel de contatos</v-card-title>

          <v-col>
              <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field label="Nome" placeholder="Nome"></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                      <v-text-field label="UH" placeholder="UH"></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                      <v-text-field label="Reserva" placeholder="Reserva"></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="btn-primary">
                      <input type="date" name="" id="">
                      <hr>
                      <input type="date" name="" id="">
                  </v-col>
              </v-row>
            
          </v-col>
        </v-card>

        <Footer />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>