import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import vuetify from '@/plugins/vuetify';
import VueResource from 'vue-resource'
import { VueMaskDirective } from "v-mask";
import './plugins/axios'
import "./plugins/excel.js"

Vue.config.productionTip = false
Vue.directive("mask", VueMaskDirective);
//Vue.http = process.env.API


new Vue({
    render: h => h(App),
    vuetify,
    router,
    VueResource
}).$mount('#app')