<template>
  <v-app>
    <Navbar />
    <v-main>
      <v-container class="p-2">
        <v-row>
          <v-col>
            <v-text-field type="date" style="width: 150px" label="Início" v-model="dataini" @keypress.enter="reloadComp"> </v-text-field>
          </v-col>
          <v-col>
            <v-text-field type="date" style="width: 150px" label="Fim" v-model="datafim" @keypress.enter="reloadComp"> </v-text-field>
            <v-btn class="mx-2" fab dark x-small color="light-green" @click="reloadComp" >
              <v-icon dark> mdi-filter </v-icon> 
            </v-btn> Buscar
          </v-col>
          <!--           <v-col>
            
          </v-col> -->
        </v-row>
      </v-container>
      
      <ListaFaleConosco :dataini="this.dataini" :datafim="this.datafim" v-if="showMsg"/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import ListaFaleConosco from "@/components/ListaFaleConosco.vue";


export default {
  
  data() {
    let data = new Date();
    let mm = ""
    if(data.getMonth() < 10){
      mm = "0"+(data.getMonth()+1)
    }else{
      mm = (data.getMonth()+1)
    }
    return {
      
      dataini: data.getFullYear()+"-"+mm+"-"+data.getDate(),
      datafim: data.getFullYear()+"-"+mm+"-"+data.getDate(),
      showMsg : true

    };
  },
  components: {
    Navbar,
    Footer,
    ListaFaleConosco,
  },
  methods:{
    reloadComp(){
      this.showMsg = false
      setTimeout(() => {
        this.showMsg = true
      }, 1000);
    }
  }
};
</script>