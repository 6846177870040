<template>
  <v-app>
    <NavBar :drawer="false" />
    <v-main>
      <v-row align="center" justify="end" class="pa-2">
        <v-col>
          <v-card class="mx-auto" width="250" outlined elevation="2">
            <v-list-item three-line>
              <v-list-item-avatar color="green"
                ><v-icon large>mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="overline mb-4">Por Tipo de Pensão</div>
                <v-list-item-subtitle v-for="tp in tipopensao" :key="tp.pensao"
                  >{{tp.pensao}} : {{tp.totalpensao}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>

            </v-card-actions>
          </v-card>
          </v-col>
          <v-col>
              
          <v-card class="mx-auto" width="350" outlined elevation="2">
            <v-list-item three-line>
              <v-list-item-avatar color="green"
                ><v-icon large>mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="overline mb-4">Por Tipo de Hóspede</div>
                <v-list-item-subtitle v-for="th in tipohospede" :key="th.tipohospede"
                  >{{th.tipohospede}} : {{th.totaltipohospede}} </v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>

            </v-card-actions>
          </v-card>
          </v-col>
          <v-col>
          <v-card class="mx-auto" width="350" outlined elevation="2">
            <v-list-item three-line>
              <v-list-item-avatar color="green"
                ><v-icon large>mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="overline mb-4">Por Cliente</div>
                <v-list-item-subtitle v-for="er in empresareservante" :key="er.empresareservante"
                  >{{er.empresareservante}} : {{er.totalempresareservante}} </v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>

            </v-card-actions>
          </v-card>
          </v-col>
          <!--<v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="blue"
                  ><v-icon large>mdi-home</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Hospedagem</div>
                  <v-list-item-title class="headline mb-1">
                    {{this.totalhospedes}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Hóspedes
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn outlined rounded text> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="yellow">
                  <i class="icon icon-emoji" style="font-size: 24px"></i>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">Avaliações</div>
                  <v-list-item-title class="headline mb-1">
                    32
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Recebidas
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn outlined rounded text> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" width="200" outlined elevation="2">
              <v-list-item three-line>
                <v-list-item-avatar color="yellow">
                  <v-icon large>mdi-antenna</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline mb-4">E-Spot</div>
                  <v-list-item-title class="headline mb-1">
                    520
                  </v-list-item-title>
                  <v-list-item-subtitle> Sessões ativas </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn outlined rounded text> Button </v-btn>
              </v-card-actions>
            </v-card>
        </v-col>-->
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
        empresareservante :[],
        tipopensao : [],
        tipohospede : []
    };
  },
  components: {
    NavBar,
    Footer,
  },
  created(){
        this.$http('/ocupacao/ocupacaoanalitico', {
          headers: { "Content-Type": "application/json", "x-access-token" : localStorage.getItem('espottoken') }
        }).then(response =>{
            if (response.status == 200) {
                this.tipopensao = response.data['tipopensao']
                this.tipohospede = response.data['tipohospede']
                this.empresareservante = response.data['empresareservante']
                console.log(this.empresareservante)
                console.log(this.tipohospede)
                console.log(this.tipopensao)
            }
        
      })
  }
};
</script>