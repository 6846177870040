<template>
    <div>
        <div v-if="downloadData" style="text-align:center;cursor:pointer;"
            @click="JSON2Excel(downloadData, titulo, headers)">
            <v-icon>mdi-microsoft-excel</v-icon>
            <v-icon style="font-weight:bold;">mdi-download</v-icon> 
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        
    }),
    methods: {
        JSON2Excel(JSONData, ReportTitle, heads) {
            // Se não tiver dados retorna...
            if (JSONData.length == 0) return;
            // Número de campos para agrupar as células do título
            var num_campos = Object.keys(JSONData[0]).length
            // Caso os cabeçalhos sejam informados...
            if (heads&&Array.isArray(heads)&&heads.length>0) {
                num_campos = heads.length
            }
            var HTML = `
            <table>
                <tr>
                    <th colspan="${num_campos}">
                        ${ReportTitle}
                    </th>
                </tr>
                <tr></tr>
            `
            var row = '<tr>'
            if (heads&&Array.isArray(heads)&&heads.length>0) {
                // Extrair os nomes dos cabeçalhos
                for (var i = 0; i < heads.length; i++) {
                    row += '<th>' + heads[i]['text'] + '</th>';
                }
            } else {
                // Extrair os nomes dos campos
                for (var field in JSONData[0]) {
                    row += '<th>' + field + '</th>';
                }
            }
            row += '</tr>\r\n'
            HTML += row
            // Primeiro loop para as linhas
            for (var j = 0; j < JSONData.length; j++) {
                row = "<tr>";
                // Segundo loop para as colunas
                if (heads&&Array.isArray(heads)&&heads.length>0) {
                    // Extrair os dados pelos cabeçalhos
                    for (var k = 0; k < heads.length; k++) {
                        row += '<th>' + JSONData[j][heads[k]['value']] + '</th>';
                    }
                } else {
                    // Extrair os dados pelas propriedades
                    for (field in JSONData[j]) {
                        row += '<td>' + JSONData[j][field] + '</td>';
                    }
                }
                // Quebra de linha...
                row += '</tr>\r\n';
                HTML += row;
            }
            HTML += '</table>';
            // Gerar um nome para o arquivo
            var fileName = "";
            // Substituir os espaços em branco do título por underline
            fileName += ReportTitle.replace(/ /g, "_");
            // Inicializar com o tipo de arquivo (xls)
            var uri = 'data:application/vnd.ms-excel;charset=utf-8, ' + escape(HTML);
            // Criar um link temporário <a /> tag
            var link = document.createElement("a");
            link.href = uri;
            // Tornar o link invisível
            link.style = "visibility:hidden";
            link.download = fileName + ".xls";
            // Acrescentar o link à página
            document.body.appendChild(link);
            // Iniciar o download
            link.click();
            // Excluir o link temporário
            document.body.removeChild(link);
        }
    },
    props:["titulo","downloadData","headers"],
    mounted() {
        
    }
}
</script>
