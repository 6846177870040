<template>
  <div class="text-center">
    <obj-array-to-excel 
      titulo="Usuários (Teste)"
      :downloadData="usuarios"
    >
    </obj-array-to-excel>
  </div>
</template>

<script>
import ObjArrayToExcel from './ObjArrayToExcel.vue';
export default {
  components: { ObjArrayToExcel },
  data() {
    return {
      usuarios: []
    };
  },
  watch:{

  },
  created(){

    if(!localStorage.getItem("espottoken")){
      window.location.href = "/";
      return
    }
      this.$http("/auth/listausuarios", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("espottoken"),
        },
      }).then((response) => {
        if (response.status == 200) {
          this.usuarios = response.data;
        }
      }).catch(error =>{
          console.log(error)
      })
  }
}



</script>