<template>
  <v-app>
    <v-container >
      <Navbar :drawer="false" />
      <v-main style="margin-bottom: 20px;">
        <v-spacer></v-spacer>
        <v-card class="m-2 p-2" max-width="600px">
          <v-card-title>
            <span style="color: rgb(29, 69, 150)">E-Mail</span>
          </v-card-title>
          <v-card-subtitle>{{ nomeUsuario }}</v-card-subtitle>
          <v-text-field
            prepend-icon="mdi-email"
            label="Para"
            type="email"
            v-model="emailTo"
            :rules="[rules.required, rules.email]"
            style="width: 250px"
          >
          </v-text-field>
          <v-text-field
            prepend-icon="mdi-more"
            label="Assunto"
            type="email"
            v-model="assunto"
            style="width: 300px; max-width: 550px;"
          >
          </v-text-field>
          <v-textarea
            v-model="mensagemEmail"
            prepend-icon="mdi-comment"
            rows="12"
            no-resize
            counter="500"
            label="E-mail"
            outlined
            style="max-width: 550px; padding-bottom: 15px"
            @keyup="contaChars"
          >
          </v-textarea>
          <v-row>
            <v-spacer></v-spacer>
            <div style="height: 50px">
              <v-col
                ><span v-if="emailOK">{{
                  responseEnvioEmail
                }}</span></v-col
              >
            </div>
            <v-spacer></v-spacer>
          </v-row>
          <v-card-actions>
            <v-btn color="green" v-bind:disabled="btnDisabled" small @click="enviarEmail">
              <v-icon>mdi-send</v-icon>
              Enviar
            </v-btn>
            <v-btn color="orange" small @click="mensagemEmail = ''">
              <v-icon>mdi-close</v-icon>
              Limpar
            </v-btn>
            <!--             <v-btn>
              Clique
            </v-btn> -->
          </v-card-actions>
        </v-card>
        <v-spacer></v-spacer>
      </v-main>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {
      nomeUsuario: localStorage.getItem("nomeUsuarioEspot"),
      emailTo : "",
      assunto : "",
      mensagemEmail: "",
      emailOK : false,
      btnDisabled: true,
      responseEnvioEmail : "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if(pattern.test(value)){
              this.btnDisabled = false;
              return true
          }else{
              this.btnDisabled = true
              return  "E-mail inválido!"
          }
        }
      },
    };
  },
  components: {
    Footer,
    Navbar,
  },
  methods: {
    contaChars() {
      if (this.mensagemEmail.length > 500) {
        this.mensagemEmail = this.mensagemEmail.substr(0, 500);
      }
    },
    async enviarEmail(){
      this.emailBody = {
        destinatario: this.emailTo,
        assunto : this.assunto,
        text : this.mensagemEmail,
        nomeusuario : this.nomeUsuario
      }

      await this.$http
        .post("/email/enviaremail", this.emailBody, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("espottoken"),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.responseEnvioEmail = "OK. Email Enviado";
            this.emailBody = {}
            this.emailTo = ""
            this.assunto = ""
            this.mensagemEmail = ""
            this.emailOK = true;
            setTimeout(() => {
              this.emailOK = false;
            }, 3000);
            console.log(response.data.message);
          } else {
            //alert(response.data.error.response);
            //console.log(response.data.error.response)
          }
        })
        .catch((error) => {
          this.responseEnvioEmail = error.response.data.error.response;
          this.emailOK = true;
          setTimeout(function () {
            this.emailOK = false;
          }, 3000);
          console.log(error.response);

        });
      }      

    },
    created(){
      if(localStorage.getItem("espottoken") == null){
        window.location.href = "/";
      }
    }

};
</script>